import mapboxConfig from '../../../../config/mapbox';

export type MapStyle = 'terrain' | 'hybrid';

export const DEFAULT_MAP_STYLE: MapStyle = 'hybrid';

export const MAP_STYLE_URLS: Record<MapStyle, string> = {
  terrain: `mapbox://styles/fishbrain-dev/${mapboxConfig.terrainStyleId}`,
  hybrid: `mapbox://styles/fishbrain-dev/${mapboxConfig.hybridStyleId}`,
};

export const TILES_STAGING_DOMAIN = 'https://tiles.staging.fishbrain.com';
export const TILES_PRODUCTION_DOMAIN = 'https://tiles.fishbrain.com';
export const RUTILUS_STAGING_DOMAIN = 'https://rutilus.staging.fishbrain.com';
export const RUTILUS_PRODUCTION_DOMAIN = 'https://rutilus.fishbrain.com';
export const API_GATEWAY_STAGING_DOMAIN = 'https://api.staging.fishbrain.com';
export const API_GATEWAY_PRODUCTION_DOMAIN = 'https://api.fishbrain.com';

export const STATIC_MAPS_FISHING_WATERS_ZOOM_LEVEL = 10;
export const STATIC_MAPS_CATCH_POSITIONS_ZOOM_LEVEL = 12;

export const MAP_MAXBOUNDS_SOUTH = -180;
export const MAP_MAXBOUNDS_WEST = -85;
export const MAP_MAXBOUNDS_NORTH = 180;
export const MAP_MAXBOUNDS_EAST = 85;

export type MapLocalStorageKey =
  | 'map.style'
  | 'map.toggleableLayer.fishingWaters'
  | 'map.toggleableLayer.spotPrediction'
  | 'map.toggleableLayer.catchPositions'
  | 'map.toggleableLayer.pointsOfInterest'
  | 'map.toggleableLayer.waypoints'
  | 'map.toggleableLayer.artificialReefs';

// DEVELOPMENT ONLY
export const DEFAULT_COORDINATES = { latitude: 27, longitude: -81 }; // Lake Okeechobee 🐟
