import gql from 'graphql-tag';

export const PageFragments = {
  id: gql`
    fragment PageId on Page {
      _id: externalId
      legacyId: id
    }
  `,
};
