import React from 'react';

import { CloseIcon } from '../../../Icons/CloseIcon';
import { Button, IButtonProps } from '../../Buttons/Button';
import { ControlLikeVariant } from '../../variants';

interface IProps extends Omit<IButtonProps, 'size'> {
  variant: ControlLikeVariant;
  width?: number;
  height?: number;
}

export const CloseButton = (props: IProps) => {
  const { height, width, ...restProps } = props;
  return (
    <Button aria-label="Close" {...restProps}>
      <CloseIcon width={width || 24} height={height || 24} />
    </Button>
  );
};
