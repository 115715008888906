import { useEffect } from 'react';

import { MAX_COOKIE_LENGTH_IN_DAYS } from '../../../constants/constants';
import { UNIVERSAL_ENV_VARS } from '../../../constants/environmentVariables';
import { getAmplitudeInstance } from '../../../helpers/analytics/getAmplitudeInstance';
import { isBrowser } from '../../../helpers/environment/isBrowser';
import { registerABTests } from '../helpers/registerABTests';

export function useInitializeAmplitude(): void {
  useEffect(() => {
    if (isBrowser() && getAmplitudeInstance() !== null) {
      getAmplitudeInstance()!.init(UNIVERSAL_ENV_VARS.AMPLITUDE_KEY, undefined, {
        platform: 'Mykiss',
        cookieExpiration: MAX_COOKIE_LENGTH_IN_DAYS,
        includeGclid: true,
        includeFbclid: true,
        includeUtm: true,
        includeReferrer: true,
        disableCookies: true,
      });
      // This needs to occur prior to any Amplitude events being sent so the user properties are
      // consistent.
      registerABTests();
    }
  }, []);
}
