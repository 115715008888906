export interface ErrorMessage {
  errorMessage: string | React.JSX.Element;
  /* The inputs this validation error should be associated with. */
  inputIds?: string[];
}

export interface IValidator<T> extends ErrorMessage {
  rule: (state: T) => boolean; // Should return true if valid.
}

export const validateForm = <T>(validators: IValidator<T>[], state: T): ErrorMessage[] =>
  validators
    .filter(validator => !validator.rule(state))
    .map(({ errorMessage, inputIds }) => ({
      errorMessage,
      inputIds,
    }));

export const validString = (value: string | undefined | null): boolean =>
  typeof value === 'string' && value.length > 0;
