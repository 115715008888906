import { searchForFishingWater } from '../api/rest/searchForFishingWater';
import { searchForLocation } from '../api/rest/searchForLocation';
import { GetAuthToken } from '../contexts/AuthContext';
import { ICoordinates } from '../interfaces/geo';
import { IFishingWater } from '../interfaces/rutilus/fishingWaters';
import { ISpeciesVerbose } from '../interfaces/rutilus/species';
import { IUserSearchAttributes } from '../interfaces/rutilus/user';
import {
  IMapContextSearchResult,
  ISearchOption,
  ISearchResult,
  ISpeciesSearchResult,
} from '../interfaces/search';

import { getFishingWaterUrl, getSpeciesUrl, getUserUrl } from './urls';

export function groupSearchResults(
  anglers: ISearchOption[],
  fishingWaters: ISearchOption[],
  species: ISearchOption[],
): ISearchResult {
  return {
    userList: {
      label: 'Anglers',
      type: 'fishing_water',
      options: anglers,
    },
    waterList: {
      label: 'Fishing Waters',
      type: 'fishing_water',
      options: fishingWaters,
    },
    speciesList: {
      label: 'Species',
      type: 'species',
      options: species,
    },
  };
}

export function groupSpeciesResults(species: ISearchOption[]): ISpeciesSearchResult {
  return {
    speciesList: {
      label: 'Species',
      type: 'species',
      options: species,
    },
  };
}

export function userToSearchOptions(users: IUserSearchAttributes[]): ISearchOption[] {
  return users.map(singleUser => ({
    title: singleUser.nickname,
    id: singleUser.external_id,
    link: getUserUrl(singleUser.nickname),
    subtitle: singleUser.nickname,
  }));
}

export function speciesToSearchOptions(species: ISpeciesVerbose[]): ISearchOption[] {
  return species.map(singleSpecies => ({
    title: singleSpecies.name,
    id: singleSpecies.external_id,
    link: getSpeciesUrl(singleSpecies.external_id, singleSpecies.name),
    subtitle: singleSpecies.species,
  }));
}

export function fishingWaterToSearchOptions(waters: IFishingWater[]): ISearchOption[] {
  return waters
    .map(result => ({
      title: result.fishing_water.name,
      id: result.fishing_water.external_id,
      link: getFishingWaterUrl(result.fishing_water.external_id, result.fishing_water.name),
      subtitle: result.tag_line,
    }))
    .concat({
      title: 'Explore nearby waters',
      id: 'nearby-water',
      link: '/explore',
      subtitle: '',
    });
}

export const fetchMapContextSearchResults = async (
  getAuthToken: GetAuthToken,
  searchValue: string,
  signal: AbortSignal,
  userPosition?: ICoordinates,
): Promise<IMapContextSearchResult> => {
  const [locationList, waterList] = await Promise.all([
    searchForLocation(searchValue, getAuthToken, signal),
    searchForFishingWater(getAuthToken, searchValue, userPosition, signal, 3),
  ]);
  return {
    locationList,
    waterList,
  };
};
