import React from 'react';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { useUserInfo } from '../../../../../../helpers/hooks/useUserInfo';
import { getUserUrl } from '../../../../../../helpers/urls';
import { getFullName } from '../../../../../../helpers/user/getFullName';
import { Avatar, AvatarSize } from '../../../../../Avatar/Avatar';
import { InternalLink } from '../../../../../Clickables/InternalLink/InternalLink';
import { Heading } from '../../../../../Heading/Heading';
import { Text } from '../../../../../Text/Text';

interface IProps {
  onExpandedChange: (value: boolean) => void;
}

export const UserDetailsSection = ({ onExpandedChange }: IProps) => {
  const { user } = useUserInfo();
  const { currentPage } = useAnalytics();

  return user ? (
    <div className="flex flex-col px-6 sm:px-11 md:px-6" data-testid="user details section">
      <div className="flex items-center">
        <Avatar alt="Angler avatar" size={AvatarSize.SMALL} src={user.avatar?.url || ''} />

        <div className="ml-4 flex-col items-center">
          <Heading variant="xs" as="p" className="m-0 text-abyss md:text-base">
            <InternalLink
              to={getUserUrl(user.nickname)}
              onClick={() => {
                onExpandedChange(false);
                void trackEvent({
                  name: 'mykiss_hamburger_clicked',
                  properties: { action: 'navigate', source: currentPage },
                });
              }}
              variant="textNone"
              className="font-semibold"
            >
              {getFullName(user)}
            </InternalLink>
          </Heading>
          <Text variant="xs" className="m-0">
            <InternalLink
              to={getUserUrl(user.nickname)}
              variant="textPrimary"
              className="flex items-center"
              onClick={() => {
                onExpandedChange(false);
                void trackEvent({
                  name: 'mykiss_hamburger_clicked',
                  properties: { action: 'navigate', source: currentPage },
                });
              }}
            >
              Your profile
            </InternalLink>
          </Text>
        </div>
      </div>
      <Heading
        variant="xs"
        as="div"
        className="m-0 border-b border-twilight py-4 text-abyss sm:py-6 md:py-4 md:text-base"
      >
        <InternalLink
          to="/profile"
          variant="textNone"
          className="flex items-center"
          onClick={() => {
            onExpandedChange(false);
            void trackEvent({
              name: 'mykiss_hamburger_clicked',
              properties: { action: 'navigate', source: currentPage },
            });
          }}
        >
          Account Settings
        </InternalLink>
      </Heading>
    </div>
  ) : (
    <></>
  );
};
