import type { AmplitudeClient } from 'amplitude-js';

import { isBrowser } from '../environment/isBrowser';

export const getAmplitudeInstance = (): AmplitudeClient | null => {
  if (isBrowser()) {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const amplitude = require('amplitude-js');
    return amplitude.getInstance();
  }
  return null;
};
