import { ISearchResult } from '../../../interfaces/search';

export const DEFAULT_RESULTS: ISearchResult = {
  userList: {
    label: 'Anglers',
    type: 'species',
    options: [],
  },
  waterList: {
    label: 'Fishing Waters',
    type: 'fishing_water',
    options: [],
  },
  speciesList: {
    label: 'Species',
    type: 'species',
    options: [],
  },
};
