import { DEFAULT_COORDINATES } from '../../../components/Maps/constants/constants';
import { ICloudfrontHeaders, ICoordinates } from '../../../interfaces/geo';

export function getCoordinatesFromLocationData(locationData: ICloudfrontHeaders | null): {
  coordinates: ICoordinates;
  source: 'default' | 'ip';
} {
  if (!locationData || locationData.latitude === null || locationData.longitude === null) {
    return { coordinates: DEFAULT_COORDINATES, source: 'default' };
  }

  return {
    coordinates: { latitude: locationData.latitude, longitude: locationData.longitude },
    source: 'ip',
  };
}
