import { FEATURE_FLAGS, FeatureFlag } from '../constants/featureFlags';

import { localStorageWrapper } from './localStorage/localStorageWrapper';

export const ACTIVE_FEATURE_FLAGS = 'featureFlag.active';

export const filterFeatureFlags = (flags: string[]): FeatureFlag[] => {
  const validFlags = Object.keys(FEATURE_FLAGS);
  return flags.filter(value => validFlags.includes(value)) as FeatureFlag[];
};

export const getActiveFeatureFlags = (): FeatureFlag[] => {
  const storedFlags = localStorageWrapper.getItem(ACTIVE_FEATURE_FLAGS);
  const flagValues = storedFlags && Array.isArray(storedFlags) ? storedFlags : [];

  const activeFlags = filterFeatureFlags(flagValues);
  return activeFlags;
};
