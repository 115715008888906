import { ICoordinates } from '../../interfaces/geo';

const GEOLOCATION_TIMEOUT = 100;
const GET_CURRENT_POSITION_TIMEOUT = 15000;

const timeout = async (): Promise<null> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(null);
    }, GET_CURRENT_POSITION_TIMEOUT);
  });
};

export const requestLocation = async (): Promise<ICoordinates | null> => {
  const promise = new Promise<ICoordinates>((resolve, reject) => {
    if (navigator && 'geolocation' in navigator) {
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(position => {
          const {
            coords: { latitude, longitude },
          } = position;
          resolve({ latitude, longitude });
        }, reject);
      }, GEOLOCATION_TIMEOUT);
    } else {
      reject();
    }
  });

  // This race check is needed to deal with a bug that can occur in MacOS. If the user has given
  // location permissions in the past, but has disabled Location Services for the browser, the
  // geolocation API will never return any value for either success of fail callbacks. To solve this
  // we wait a few seconds and then return as if there was an error.
  return Promise.race([
    // We catch here because we don't need to do anything with the error.
    promise.catch(() => null),
    timeout(),
  ]);
};
