import { ReadonlyURLSearchParams } from 'next/navigation';

import { QueryStringKey } from '../url/queryUtils';

export const getIsUA = (searchParams: ReadonlyURLSearchParams): boolean => {
  return (
    searchParams.has(QueryStringKey.GoogleClickIdentifier) ||
    searchParams.has(QueryStringKey.FacebookClickIdentifier)
  );
};
