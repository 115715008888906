import {
  useQuery,
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';

import { useAuth } from '../../contexts/AuthContext';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { reportMykissError } from '../../helpers/errorHandling';

import { handleApolloErrorOnClient } from './shared';

// NOTE: The signature of this hook mimics that of useQuery, so if useQuery changes we should also
// update this.
export function useRutilusQuery<TData = any, TVariables extends OperationVariables = {}>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> = {},
): QueryResult<TData, TVariables> {
  const { getToken, invalidateAccessToken } = useAuth();
  const result = useQuery(query, {
    ...options,
    skip: !isBrowser() || options.skip,
    context: { ...options.context, getAuthToken: getToken },
  });

  if (result.error) {
    if (result.error.message === 'Response not successful: Received status code 401') {
      invalidateAccessToken();
      reportMykissError(new Error('User had invalid access token'), undefined, 'warning');
      result.refetch().catch(reportMykissError);
    } else {
      handleApolloErrorOnClient(result.error, query, options as QueryHookOptions, 'rutilus');
    }
  }

  return result;
}
