import pino from 'pino';

import { isBrowser } from './environment/isBrowser';
import { isDevelopment } from './environment/isDevelopment';

const baseOptions = {
  level: isDevelopment() ? 'debug' : 'info',
  formatters: {
    level: (level: string) => ({ level }),
  },
};
const pinoOptions = isBrowser() ? { ...baseOptions, browser: { asObject: true } } : baseOptions;
export const logger = pino(pinoOptions);
