import { useRouter } from 'next/navigation';

import { reportMykissError } from '../../../helpers/errorHandling';
import { ClickableSize, ClickableVariant, getVariantClass } from '../variants';

export interface IInternalLinkProps {
  onClickEvent?: (event: any | undefined) => void;
  className?: string;
  routerPushTo: string;
  ariaLabel: string;
  children: React.ReactNode;
  variant?: ClickableVariant;
  size?: ClickableSize;
}

/*
The non Crawlable Link component is set up to work just like the internal Link component, but importantly
for SEO it cannot be found by the google crawl bot and thus does not use up our websites 'crawl budget'.

This component should be used in places where we are adding things to the url like flags or map coordinates
where we don't want google to find and index those links with random query params.

We deliberately don't provide a default variant so we can use this component on cards.
*/

export const NonCrawlableLink = (props: IInternalLinkProps) => {
  const {
    className = '',
    size = 'default',
    variant,
    routerPushTo,
    ariaLabel,
    onClickEvent,
    children,
  } = props;
  const { push } = useRouter();

  const handleNonCrawlClick = (e: any | undefined): void => {
    if (onClickEvent) {
      onClickEvent(e);
    }
    try {
      push(routerPushTo);
    } catch (error) {
      reportMykissError(error);
    }
  };

  return (
    <div
      onClick={e => {
        handleNonCrawlClick(e);
      }}
      onKeyDown={e => {
        handleNonCrawlClick(e);
      }}
      role="button"
      tabIndex={0}
      aria-label={ariaLabel}
      className={`${className} ${variant ? getVariantClass(variant, size) : ''}`}
      data-testid="NonCrawlableLink"
    >
      {children}
    </div>
  );
};
