import {
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  MutationHookOptions,
  MutationTuple,
  useMutation,
} from '@apollo/client';

import { useAuth } from '../../contexts/AuthContext';
import { reportMykissError } from '../../helpers/errorHandling';

// NOTE: The signature of this hook mimics that of useMutation, so if useMutation changes we should also
// update this.
export function useRutilusMutation<TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables> = {},
): MutationTuple<TData, TVariables, any> {
  const { getToken, invalidateAccessToken } = useAuth();
  const result = useMutation(mutation, {
    ...options,
    context: { ...options.context, getAuthToken: getToken },
  });

  if (result[1].error) {
    if (result[1].error.message.includes('an object of type Mutation is not accessible')) {
      invalidateAccessToken();
      reportMykissError(new Error('User had invalid access token'), undefined, 'warning');
    } else {
      reportMykissError(result[1].error);
    }
  }

  return result;
}
