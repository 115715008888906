import React from 'react';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { InternalLink } from '../../../../../Clickables/InternalLink/InternalLink';

interface IProps {
  onExpandedChange: (value: boolean) => void;
}

export const LogOutButton = ({ onExpandedChange }: IProps) => {
  const { currentPage } = useAnalytics();

  return (
    <div className="flex items-start px-6 pt-0 sm:px-11 md:px-6">
      <InternalLink
        variant="buttonSecondary"
        to="/logout"
        className="px-11 text-base md:w-full"
        onClick={() => {
          onExpandedChange(false);
          void trackEvent({
            name: 'mykiss_hamburger_clicked',
            properties: { action: 'navigate', source: currentPage },
          });
        }}
      >
        Log out
      </InternalLink>
    </div>
  );
};
