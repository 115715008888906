import { getDomain } from '../../helpers/urls';

export interface ITokens {
  accessToken: {
    jwtToken: string;
    expiration: number;
  };
  refreshToken: string;
  idToken: {
    jwtToken: string;
  };
}

const PATH = '/api/_cognito/authenticate-user';

export async function getTokensWithUsernameAndPassword(
  email: string,
  password: string,
): Promise<ITokens> {
  return fetch(`${getDomain()}${PATH}`, {
    body: JSON.stringify({ username: email, password }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
  }).then(async response => {
    const json = await response.json();
    if (response.ok) {
      return json;
    }
    throw json;
  });
}
