import { AnchorHTMLAttributes, MouseEventHandler, RefObject } from 'react';

import NextLink from 'next/link';

import { getVariantClass, IClickableProps } from '../variants';

export interface IInternalLinkProps
  extends IClickableProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  disabled?: boolean;
  ref?: RefObject<HTMLAnchorElement>;
  onClick?: MouseEventHandler<any>;
  className?: string;
  style?: object;
  to: string;
  ariaLabel?: string;
}

export const InternalLink = (props: IInternalLinkProps) => {
  const {
    disabled,
    className = '',
    size,
    variant = 'textPrimarySans',
    to,
    ariaLabel,
    children,
    ...rest
  } = props;

  if (disabled) {
    // This throws a type error because span shouldn't get the event listeners, but in this case
    // were fine with it as the intention is that they should do nothing.

    // classes applied manually since span won't recognized disabled prop
    if (variant.includes('text')) {
      return (
        <span
          className={`${className} ${getVariantClass(
            variant,
            size,
          )} pointer-events-none cursor-not-allowed text-disabled transition-colors`}
          {...rest}
        >
          {children}
        </span>
      );
    }
    if (variant.includes('button')) {
      return (
        <span
          className={`${className} ${getVariantClass(
            variant,
            size,
          )} pointer-events-none cursor-not-allowed bg-disabled text-twilight`}
          style={{ backgroundColor: 'rgb(157 161 166)' }}
          {...rest}
        >
          {children}
        </span>
      );
    }
    return <span className={`${className} ${getVariantClass(variant, size)}`} {...rest} />;
  }

  return (
    <NextLink href={to} className={`${className} ${getVariantClass(variant, size)}`} {...rest}>
      {children}
    </NextLink>
  );
};
