export const parseInternalId = (id: string): number | null => {
  if (!id) {
    return null;
  }
  const internalId = Number(id);
  return Number.isNaN(internalId) ? null : internalId;
};

export const getRandomInteger = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;
