/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { CSSProperties, useEffect } from 'react';

import usePortal from 'react-useportal';
import { twMerge } from 'tailwind-merge';

import { CloseButton } from '../Clickables/Controls/CloseButton/CloseButton';
import { ControlLikeVariant } from '../Clickables/variants';

import styles from './Modal.module.css';

interface CloseOptions {
  disableCloseButton?: boolean;
  disableClickOutside?: boolean;
  disableEscapeKey?: boolean;
}

export interface IModalProps {
  /* Generally you should not need to manually specify the open state, but if you need to you can
  using this prop. Be aware that this will only affect the initial render state, so changing this
  prop later will have no effect. To use this to programatically toggle the modal you will need
  to do something like
  ```
    isVisible ? <Modal isOpen={isVisible} onClose={() => setIsVisible(false)} /> : <></>
  ```
  */
  isOpen?: boolean;
  size?: 'md' | 'lg' | 'xl' | 'full';
  children?: React.ReactNode | React.ReactNode[];
  trigger?: (props: { onClick: () => void }) => React.JSX.Element;
  /* Disable close button, escape key, or clicking outside modal. If undefined all close actions will
  be enabled. If true all close actions will be disabled If close button is disabled it will also
  be hidden. */
  disableClose?: CloseOptions | boolean;
  /* Callback for when the modal is closed. */
  onClose?: () => void;
  onOpen?: () => void;
  outerClassName?: string;
  className?: string;
  style?: CSSProperties;
  dismissButtonLeft?: boolean;
  buttonClass?: string;
  closeButtonVariant?: ControlLikeVariant;
  dataTestId?: string;
}

const DEFAULT_CLOSE_OPTIONS = {
  disableClickOutside: false,
  disableCloseButton: false,
  disableEscapeKey: false,
};

function getModalSizeClass(size?: string): string {
  if (size === 'lg') {
    return styles.lg;
  }
  if (size === 'xl') {
    return styles.xl;
  }
  if (size === 'full') {
    return styles.full;
  }
  return styles.default;
}

function getCloseOptions(closeProps: IModalProps['disableClose']): CloseOptions {
  if (closeProps === true) {
    return { disableClickOutside: true, disableCloseButton: true, disableEscapeKey: true };
  }
  if (closeProps === undefined || closeProps === false) {
    return DEFAULT_CLOSE_OPTIONS;
  }
  return { ...DEFAULT_CLOSE_OPTIONS, ...closeProps };
}

export const Modal = ({
  size,
  children,
  trigger: Trigger,
  disableClose,
  isOpen: forceIsOpen,
  onClose,
  onOpen,
  outerClassName,
  className = '',
  style = {},
  dismissButtonLeft = false,
  buttonClass,
  closeButtonVariant = 'controlWhiteBg',
  dataTestId,
}: IModalProps) => {
  const { disableClickOutside, disableCloseButton, disableEscapeKey } =
    getCloseOptions(disableClose);
  const { openPortal, closePortal, isOpen, Portal } = usePortal({
    isOpen: forceIsOpen,
    closeOnOutsideClick: false,
    closeOnEsc: !disableEscapeKey,
    onClose,
    onOpen,
  });
  if (!Trigger && isOpen === undefined) {
    throw new Error(
      'Modal requires a `trigger` prop unless you are manually handling the state using the `isOpen` prop.',
    );
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Portal>
          <div
            className={twMerge(
              'fixed left-0 top-0 z-modal h-full w-full overflow-x-hidden transition-all',
              outerClassName,
            )}
            style={{ backgroundColor: 'rgba(0,0,0,0.4)' }}
            onClick={event => {
              event.stopPropagation();
              if (!disableClickOutside) {
                closePortal();
              }
            }}
          >
            <div className="flex h-auto min-h-screen w-full flex-wrap items-center justify-center">
              <div
                onClick={e => {
                  e.stopPropagation();
                }}
                className={twMerge(
                  `grid max-w-full grid-cols-1 grid-rows-1 overflow-hidden rounded-sm bg-white shadow ${getModalSizeClass(
                    size,
                  )}`,
                  className,
                )}
                style={{
                  ...style,
                }}
              >
                {!disableCloseButton && (
                  <CloseButton
                    variant={closeButtonVariant}
                    className={`z-mid mb-auto mt-6 ${
                      dismissButtonLeft ? 'ml-6 mr-auto' : 'ml-auto mr-6'
                    } ${buttonClass || ''}`}
                    style={{ gridColumn: 1, gridRow: 1 }}
                    onClick={closePortal}
                    data-testid="closebtn"
                    id="close-modal-button"
                  />
                )}

                <div
                  className="flex flex-col"
                  style={{ gridColumn: 1, gridRow: 1 }}
                  data-testid={dataTestId}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
      {Trigger && <Trigger onClick={openPortal} />}
    </>
  );
};
