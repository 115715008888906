import { reportMykissError } from '../../helpers/errorHandling';
import { pathToUrl } from '../../helpers/urls';
import { ICloudfrontHeaders } from '../../interfaces/geo';

export async function getLocationData(): Promise<ICloudfrontHeaders | null> {
  try {
    const response = await fetch(pathToUrl('/api/location-info'));
    const cloudfrontLocationData = await response.json();
    if (cloudfrontLocationData) {
      return {
        ...cloudfrontLocationData,
        latitude: parseFloat(cloudfrontLocationData.latitude) || null,
        longitude: parseFloat(cloudfrontLocationData.longitude) || null,
      };
    }

    reportMykissError(new Error('Cloudfront location data is not found'), undefined, 'info');
  } catch (error) {
    reportMykissError(error);
  }

  return null;
}
