// NOTE: This file is separated from authentication.ts as in needs to run under the NextJS Middleware
// runtime which has a restricted API - in particular we can't currently use Bugsnag here. Please
// be cautious if importing new files/modules here and test NextJS server renders.

import { NextRequest, NextResponse } from 'next/server';
import { CookieSetOptions } from 'universal-cookie';

import { getCookies } from '../cookies';

const MAX_COOKIE_LENGTH_IN_DAYS = 180;
const REFRESH_BUFFER_IN_SECONDS = 120;
export const ACCESS_TOKEN_KEY = 'web-auth-v2-access-token';
export const REFRESH_TOKEN_KEY = 'web-auth-v2-refresh-token';
const ID_TOKEN_KEY = 'web-auth-v2-id-token';

const MS_IN_A_SECOND = 1000;
const dateFromSeconds = (seconds: number): Date => new Date(seconds * MS_IN_A_SECOND);

export interface IOptions {
  nextMiddlewareContext?: { response: NextResponse; request: NextRequest };
}

function getDefaultCookieOptions(): { path: string; expires: Date } {
  const cookieExpiryDate = new Date();
  cookieExpiryDate.setDate(cookieExpiryDate.getDate() + MAX_COOKIE_LENGTH_IN_DAYS);
  return {
    path: '/',
    expires: cookieExpiryDate,
  };
}

export function getCookie(key: string, { nextMiddlewareContext }: IOptions = {}): string {
  if (nextMiddlewareContext) {
    return nextMiddlewareContext.request.cookies.get(key)?.value || '';
  }
  return getCookies().get(key) || '';
}

export function setCookie(
  key: string,
  value: string,
  cookieOptions: CookieSetOptions,
  { nextMiddlewareContext }: IOptions = {},
): void {
  if (nextMiddlewareContext) {
    nextMiddlewareContext.response.cookies.set(key, value, cookieOptions);
  } else {
    getCookies().set(key, value, cookieOptions);
  }
}

function removeCookie(key: string, { nextMiddlewareContext }: IOptions = {}): void {
  if (nextMiddlewareContext) {
    nextMiddlewareContext.response.cookies.delete(key);
  } else {
    getCookies().remove(key, getDefaultCookieOptions());
  }
}

export function getIdToken(options?: IOptions): string {
  return getCookie(ID_TOKEN_KEY, options);
}

export function _destroyAccessToken(options?: IOptions): void {
  removeCookie(ACCESS_TOKEN_KEY, options);
}

export function destroyTokens(options?: IOptions): void {
  removeCookie(ACCESS_TOKEN_KEY, options);
  removeCookie(REFRESH_TOKEN_KEY, options);
  removeCookie(ID_TOKEN_KEY, options);
}

export function _getRefreshToken(options?: IOptions): string {
  return getCookie(REFRESH_TOKEN_KEY, options);
}

export function hasRefreshToken(options?: IOptions): boolean {
  const refreshToken = _getRefreshToken(options);
  return typeof refreshToken === 'string' && refreshToken !== '' && refreshToken !== 'undefined';
}

export function _setRefreshToken(refreshToken: string, options?: IOptions): void {
  setCookie(
    REFRESH_TOKEN_KEY,
    refreshToken,
    {
      ...getDefaultCookieOptions(),
    },
    options,
  );
}

/**
 * Although this is exported, it is only intended for use with AuthContext and should not be directly
 * used!
 */
export function _getAccessToken(options?: IOptions): string {
  return getCookie(ACCESS_TOKEN_KEY, options);
}

// This is only exported for use when initially loading the app as our middleware will have refreshed
// any invalid access tokens.
export function _hasAccessToken(options?: IOptions): boolean {
  return (
    typeof _getAccessToken(options) === 'string' &&
    _getAccessToken(options) !== '' &&
    _getAccessToken(options) !== 'undefined'
  );
}

export function _setAccessToken(
  accessToken: string,
  expiryDateInSeconds: number,
  options?: IOptions,
): void {
  setCookie(
    ACCESS_TOKEN_KEY,
    accessToken,
    {
      ...getDefaultCookieOptions(),
      // We set the cookie expiry a little earlier to avoid race conditions
      expires: dateFromSeconds(expiryDateInSeconds - REFRESH_BUFFER_IN_SECONDS),
    },
    options,
  );
}

export function _setIdToken(idToken: string, options?: IOptions): void {
  setCookie(
    ID_TOKEN_KEY,
    idToken,
    {
      ...getDefaultCookieOptions(),
    },
    options,
  );
}
