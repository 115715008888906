import classNames from 'classnames';

import styles from './Clickables.module.css';

const BUTTON_CLASSES = 'inline-flex items-center font-heading font-medium ';
const TEXT_CLASSES = 'disabled:text-disabled disabled:cursor-not-allowed transition-colors';
const CONTROL_CLASSES = 'inline-flex items-center font-heading font-medium text-base p-2';

const DISABLED = 'disabled:cursor-not-allowed disabled:bg-disabled disabled:text-twilight';
const TERTIARY_DISABLED = 'disabled:cursor-not-allowed disabled:text-disabled';

const CONTROL_DISABLED = 'disabled:cursor-not-allowed disabled:bg-lake-fog disabled:text-twilight';

const textLikeVariants = {
  textDeprecatedLight: `font-heading font-medium text-white hover:text-midnight ${TEXT_CLASSES}`,
  textPrimary: `font-heading font-medium text-deep-sea hover:text-trench active:text-deep-sea-active ${TEXT_CLASSES}`,
  textPrimaryDarkBg: `font-heading font-medium text-pinktail-shade hover:text-pinktail active:text-pinktail-active ${TEXT_CLASSES}`,
  textPrimarySans: `font-sans font-semibold text-deep-sea hover:text-trench active:text-deep-sea-active ${TEXT_CLASSES}`,
  textWhite: `font-sans font-semibold text-sea-foam hover:text-pinktail-shade active:text-pinktail-active ${TEXT_CLASSES}`,
  textNone: '',
  disabled: '', // remove in FIB-37696
} as const;

const buttonLikeVariants = {
  buttonPrimary: `${styles.button} ${styles.primary} ${DISABLED}`,
  buttonPrimaryVariant: `${styles.button} ${styles.primaryVariant} ${DISABLED}`,
  buttonSecondary: `${styles.button} ${styles.secondary} ${DISABLED}`,
  buttonSecondaryVariant: `${styles.button} ${styles.secondaryVariant} ${DISABLED}`,
  buttonTertiary: `${styles.button} ${styles.tertiary} ${TERTIARY_DISABLED}`,
  buttonTertiaryVariant: `${styles.button} ${styles.tertiaryVariant} ${DISABLED}`,
  buttonDanger: `${styles.button} ${styles.danger} ${DISABLED}`,
  buttonPro: `${styles.button} ${styles.pro} ${DISABLED}`,
  buttonOnColorful: `${styles.button} ${styles.onColorful} ${DISABLED}`,
  buttonNone: 'ring-transparent',
} as const;

const controlLikeVariants = {
  controlWhiteBg: `${styles.button} ${styles.secondary} ${CONTROL_DISABLED}`,
  controlLakeFogBg: `${styles.button} ${styles.secondaryVariant} ${CONTROL_DISABLED}`,
  controlOnColorfulBg: `${styles.button} ${styles.onColorful} ${CONTROL_DISABLED}`,
};

export const sizes = {
  xs: 'px-3 py-[5px] text-xs rounded-sm',
  sm: 'px-3 py-[7.5px] rounded-md text-sm',
  default: `p-3 rounded-lg`,
  none: '',
};

export type ControlLikeVariant = keyof typeof controlLikeVariants;
export type ClickableVariant =
  | keyof typeof textLikeVariants
  | keyof typeof buttonLikeVariants
  | ControlLikeVariant;
export type ClickableSize = keyof typeof sizes;

export interface IClickableProps {
  variant?: ClickableVariant;
  size?: ClickableSize;
}

export function getVariantClass(
  variant?: ClickableVariant,
  size: ClickableSize = 'default',
): string {
  if (!variant) {
    return '';
  }
  if (variant.startsWith('button')) {
    return classNames(
      buttonLikeVariants[variant as keyof typeof buttonLikeVariants],
      BUTTON_CLASSES,
      sizes[size],
      'justify-center',
    );
  }
  if (variant.startsWith('control')) {
    // There's intentionally no size variants applied here.
    return classNames(
      controlLikeVariants[variant as keyof typeof controlLikeVariants],
      CONTROL_CLASSES,
      'justify-center',
      'rounded-lg',
    );
  }
  return textLikeVariants[variant as keyof typeof textLikeVariants];
}
