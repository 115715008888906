import { get } from 'ts-get';

interface _Edge {
  node?: {} | null;
}

export interface NonNullEdge<Node> {
  node: Node;
}

// This helper function extracts the nodes from a GQL connection into an array an filters out any
// null edges/nodes.
export function getNodesFromConnection<Node>(
  connection?: {
    edges: ({ node: Node | null } | null)[] | null;
  } | null,
): Node[] {
  return get(connection, c => c.edges.filter(e => e && e.node).map(e => e.node), []) as Node[];
}

// This helper function extracts the edges from a GQL connection into an array and filters out any
// null edges/nodes.
export function getEdgesFromConnection<Node, Edge extends _Edge>(
  connection?: {
    edges: (Edge | null)[] | null;
  } | null,
): (Edge & NonNullEdge<Node>)[] {
  return (connection?.edges?.filter(e => e && e.node) as (Edge & NonNullEdge<Node>)[]) || [];
}
