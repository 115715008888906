import * as React from 'react';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { variants } from './variants';

type HeadingColor =
  | 'dark'
  | 'light'
  | 'highlight'
  | 'navHighlight'
  | 'abyss'
  | 'deep'
  | 'disabled'
  | 'pinktail';

export type HeadingVariant = keyof typeof variants;

export interface IHeadingProps {
  className?: string;
  withDefaults?: boolean;
  children: React.ReactNode;
  variant?: HeadingVariant;
  color?: HeadingColor;
  as?: string;
  style?: React.CSSProperties;
  title?: string;
  id?: string;
}

function getColor(color: HeadingColor): string {
  if (color === 'dark') {
    return 'text-trench';
  }
  if (color === 'light') {
    return 'text-sea-foam';
  }
  if (color === 'navHighlight') {
    return 'text-pinktail-shade';
  }
  if (color === 'abyss') {
    return 'text-abyss';
  }
  if (color === 'deep') {
    return 'text-deep-sea';
  }
  if (color === 'disabled') {
    return 'text-disabled';
  }
  if (color === 'pinktail') {
    return 'text-pinktail';
  }
  return 'text-clownfish';
}

export const Heading = ({
  className,
  withDefaults = true,
  variant = 'lg',
  as = 'h2',
  color = 'dark',
  children,
  ...rest
}: IHeadingProps) => {
  const headingClass = twMerge(
    classNames(
      {
        'font-heading': withDefaults,
      },
      variants[variant],
      getColor(color),
    ),
    className,
  );

  const HeadingTag = as as keyof React.JSX.IntrinsicElements;

  return (
    <HeadingTag className={headingClass} {...rest}>
      {children}
    </HeadingTag>
  );
};
