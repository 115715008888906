import { trackEvent } from '../../../helpers/analytics/trackEvent';
import { EventMykissSearchResultSelected } from '../../../interfaces/events';
import { ISearchGroup, ISearchOption } from '../../../interfaces/search';

export const trackOptionSelected = (
  option: ISearchOption,
  source: EventMykissSearchResultSelected['properties']['source'],
  currentPage: string,
  group: ISearchGroup,
  searchTerm: string,
): void => {
  void trackEvent({
    name: 'mykiss_search_result_selected',
    properties: {
      source,
      page: currentPage,
      item_name: option.title,
      item_id: option.id,
      item_type: group.type,
      search_term: searchTerm,
    },
  });
};
