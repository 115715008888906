import { useEffect } from 'react';

import { datadogRum } from '@datadog/browser-rum-slim';

import { DATADOG_RUM_CONFIG } from '../../../constants/constants';
import { isBrowser } from '../../../helpers/environment/isBrowser';
import { isProduction } from '../../../helpers/environment/isProduction';
import { isStaging } from '../../../helpers/environment/isStaging';

function isDatadogRUMInstantiated(): boolean {
  // Slightly hacky way of checking if we've already initialised.
  return datadogRum.getInitConfiguration() !== undefined;
}

// Initialises DatadogRUM
export function useDatadogRUM(): void {
  useEffect(() => {
    if (isBrowser() && (isProduction() || isStaging())) {
      if (!isDatadogRUMInstantiated()) {
        datadogRum.init(DATADOG_RUM_CONFIG);
      }
    }
  }, []);
}
