export const DEFAULT_ICON_PROPS: IIconProps = {
  size: undefined,
  width: 24,
  height: 24,
  title: '',
  ariaHidden: false,
  className: '',
  stroke: 'currentcolor',
  strokeWidth: 2,
};

export interface IIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  title?: string;
  ariaLabel?: string;
  ariaHidden?: boolean;
}
