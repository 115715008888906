import gql from 'graphql-tag';

import { FishingMethodFragments } from './FishingMethodType';
import { FishingWaterFragments } from './FishingWaterType';
import { ImageFragments } from './ImageType';
import { ProductFragments } from './ProductType';
import { ProductUnitFragments } from './ProductUnitType';
import { SpeciesFragments } from './SpeciesTypes';
import { WeatherAndMarineReadingFragments } from './WeatherAndMarineReadingType';
import { WindDirectionFragments } from './WindDirectionType';

export const CatchFragments = {
  id: gql`
    fragment CatchId on Catch {
      _id: externalId
    }
  `,
  location: gql`
    fragment CatchLocation on Catch {
      latitude
      longitude
    }
  `,
  metadata: gql`
    fragment CatchMetadata on Catch {
      __typename
      _id: externalId
      legacyId: id
      createdAt
    }
  `,
  productUnits: gql`
    fragment CatchProductUnits on Catch {
      __typename
      productUnits(first: 20) {
        edges {
          node {
            ...ProductUnitId
            model
            image {
              ...ImageFields
            }
            product {
              ...ProductId
              ...ProductBrandName
              displayName
            }
          }
        }
      }
    }
    ${ImageFragments.imageFields}
    ${ProductFragments.brandName}
    ${ProductFragments.id}
    ${ProductUnitFragments.id}
  `,
  fishingWaterName: gql`
    fragment CatchFishingWaterName on Catch {
      fishingWater {
        ...FishingWaterId
        displayName
        latitude
        longitude
      }
    }
    ${FishingWaterFragments.id}
  `,
  fishingMethodName: gql`
    fragment CatchFishingMethodName on Catch {
      fishingMethod {
        ...FishingMethodId
        displayName
      }
    }
    ${FishingMethodFragments.id}
  `,
  speciesName: gql`
    fragment CatchSpeciesName on Catch {
      species {
        ...SpeciesId
        displayName
      }
    }
    ${SpeciesFragments.id}
  `,
  weatherFields: gql`
    fragment CatchWeatherFields on Catch {
      weatherAndMarineReading {
        ...WeatherAndMarineReadingId
        __typename
        airPressure
        airHumidity
        airTemperature
        waterTemperature
        weatherCondition {
          localizedValue
          worldWeatherOnlineIdentifier
        }
        windDirection {
          ...WindDirectionId
          __typename
          localizedValue
          shortLocalizedValue
          degrees
        }
        windSpeed
      }
    }
    ${WeatherAndMarineReadingFragments.id}
    ${WindDirectionFragments.id}
  `,
};
