import { useState, useEffect } from 'react';

import { useAuth, GetAuthToken } from '../../contexts/AuthContext';
import { reportMykissError } from '../../helpers/errorHandling';
import { useClientHasRendered } from '../../helpers/hooks';

interface IState<Response> {
  data?: Response;
  isLoading: boolean;
  error?: unknown;
}

/**
 * This hook can be used for sending rest requests to Rutilus. It works in a similar way to Apollo's
 * useQuery hook, returung an isLoading value, and a data value once the request is complete. By
 * default it will only run client side (this is usually what you want, as we have no way currently
 * to pass REST fetched data from the server to the client like we do with graphQL).
 */
export function useRutilusRestQuery<Response, Variables>(
  queryFunction: (
    variables: Variables,
    getAuthToken: GetAuthToken,
    signal: AbortSignal,
  ) => Promise<Response>,
  variables: Variables,
  { skip = false, callOnServer = false }: { skip?: boolean; callOnServer?: boolean },
): IState<Response> {
  const [state, setState] = useState<IState<Response>>({ isLoading: !skip });
  const clientHasRendered = useClientHasRendered();
  const { getToken } = useAuth();

  const requestAllowed = callOnServer || clientHasRendered;

  useEffect(() => {
    const controller = new AbortController();
    if (!skip && requestAllowed) {
      const { signal } = controller;
      setState({ isLoading: true });
      queryFunction(variables, getToken, signal)
        .then(response => {
          if (controller.signal.aborted) {
            return;
          }
          setState({ isLoading: false, data: response });
        })
        .catch(error => {
          // Calling abort signal on a network call is not an error and should not be sent to bugsnag
          if (error.name === 'AbortError') {
            return;
          }
          reportMykissError(error);
          setState({ isLoading: false, error });
        });
    }

    return () => {
      controller.abort();
    };
    // We need to pass the variables obj as a dependency, but that causes a loop, so we stringify it.
  }, [JSON.stringify(variables), skip, requestAllowed]);

  return state;
}
