interface IUserInterface {
  first_name?: string;
  last_name?: string;
  firstName?: string | null;
  lastName?: string | null;
}

export const getFullName = (user: IUserInterface): string => {
  if (!user.firstName && !user.first_name && !user.lastName && !user.last_name) {
    return 'Anonymous Angler Fish';
  }
  return [user.first_name || user.firstName, user.last_name || user.lastName]
    .filter(x => !!x)
    .join(' ');
};
