/* This context is used for storing state that may need to be accessed from anywhere in the app,
or that we need to keep track on for the entirety of a user session. */
import React, { ReactNode, useEffect, useState } from 'react';

import { useQueryString } from '../helpers/hooks/useQueryString';
import { QueryStringKey } from '../helpers/url/queryUtils';

export interface ISessionState {
  watersSignupWallDisplayed: boolean;
  numberOfWatersViewed: number;
  initialClientRenderComplete: boolean;
  isUA: boolean; // i.e. isUserAcquistion
  incrementNumberOfWatersViewed: () => void;
  setWatersSignupWallDisplayed: (isDisplayed: boolean) => void;
  setInitialClientRenderComplete: (isComplete: boolean) => void;
}

const SessionContext = React.createContext<ISessionState>({
  watersSignupWallDisplayed: false,
  numberOfWatersViewed: 0,
  initialClientRenderComplete: false,
  isUA: false,
  incrementNumberOfWatersViewed: () => undefined,
  setWatersSignupWallDisplayed: (_: boolean) => undefined,
  setInitialClientRenderComplete: (_: boolean) => undefined,
});

export interface ISessionProviderProps {
  children: ReactNode;
  // Can be used for unit tests. If provided, all useEffects with mutations or data fetches will be
  // skipped at the values added to testState will override the context response.
  testState?: Partial<ISessionState>;
}

function SessionProvider({ testState = {}, ...props }: ISessionProviderProps) {
  const [isUA, setIsUA] = useState(false);
  const [numberOfWatersViewed, setNumberOfWatersViewed] = useState(0);
  const [initialClientRenderComplete, setInitialClientRenderComplete] = useState(false);
  const [watersSignupWallDisplayed, setWatersSignupWallDisplayed] = useState(false);

  const gclId = useQueryString(QueryStringKey.GoogleClickIdentifier);
  const fbclid = useQueryString(QueryStringKey.FacebookClickIdentifier);

  useEffect(() => {
    // If gclid or fbclid param exists we tag the user as UA. If the route changes later on the user remains UA.
    if (!isUA && (gclId || fbclid)) {
      setIsUA(true);
    }
  }, [gclId, fbclid, isUA, setIsUA]);

  return (
    <SessionContext.Provider
      {...props}
      value={{
        isUA,
        numberOfWatersViewed,
        initialClientRenderComplete,
        watersSignupWallDisplayed,
        incrementNumberOfWatersViewed: () => {
          setNumberOfWatersViewed(numberOfWatersViewed + 1);
        },
        setWatersSignupWallDisplayed,
        setInitialClientRenderComplete,
        ...testState,
      }}
    />
  );
}

const useSession = (): ISessionState => {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

export { SessionProvider, useSession };
