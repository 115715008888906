import React from 'react';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import get from 'ts-get';

import { useRutilusQuery } from '../../../../../../api/graphql/useRutilusQuery';
import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { useIsMobileDevice } from '../../../../../../helpers/hooks/useIsMobileDevice';
import { objectToQueryString, queryStringToObject } from '../../../../../../helpers/url/queryUtils';
import {
  GetManageablePageStatuses,
  GetManageablePageStatuses_entry_manageablePages_edges as PageEdge,
  GetManageablePageStatuses_entry_manageablePages_edges_node,
} from '../../../../../../interfaces/graphql';
import { getManageablePageStatuses } from '../../../../../../queries/rutilus/Me';
import { Button } from '../../../../../Clickables/Buttons/Button';
import { Heading } from '../../../../../Heading/Heading';
import { EditIcon } from '../../../../../Icons/EditIcon';
import { StatsIcon } from '../../../../../Icons/StatsIcon';
import { StatusIndicator } from '../../../../../StatusIndicator/StatusIndicator';

import { BrandLabel } from './BrandLabel';

export interface IPageMenuItemsProps {
  navigateTo: (path: string) => void;
  onExpandedChange: (value: boolean) => void;
}

export const BrandMenu = ({ navigateTo, onExpandedChange }: IPageMenuItemsProps) => {
  const { data, error, loading } =
    useRutilusQuery<GetManageablePageStatuses>(getManageablePageStatuses);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { replace } = useRouter();
  const { currentPage } = useAnalytics();
  const isMobileDevice = useIsMobileDevice();

  if (error || loading || !data) {
    return <></>;
  }

  const openCreatePostModal = (): void => {
    const search = queryStringToObject(searchParams?.toString());
    const url = `${pathname}?${objectToQueryString({ ...search, add_post: 'show' })}`;

    replace(url);
    onExpandedChange(false);
  };

  const pages = get<GetManageablePageStatuses, PageEdge[]>(
    data,
    it => it.entry.manageablePages.edges,
    [],
  )
    .map(i => i.node)
    .filter((page): page is GetManageablePageStatuses_entry_manageablePages_edges_node => !!page);

  return pages.length > 0 ? (
    <Heading variant="xs" as="div" className="m-0 mb-4 text-abyss md:text-base">
      Brands
      <div className="mt-4">
        {pages.map(page => {
          return (
            <div key={page.legacyId} className="flex items-center pb-4">
              <BrandLabel navigateTo={navigateTo} page={page}>
                <StatusIndicator
                  id={`page-status-${page.legacyId}`}
                  status={page.published}
                  tooltipDescription={page.published ? 'Published' : 'Not Published'}
                />
                {page.name}
              </BrandLabel>

              <Button
                variant="textPrimary"
                className="ml-auto text-pinktail-shade"
                onClick={() => {
                  navigateTo(`/me/pages/${page.legacyId}`);
                  void trackEvent({
                    name: 'mykiss_hamburger_clicked',
                    properties: { action: 'navigate', source: currentPage },
                  });
                }}
              >
                <EditIcon size={16} />
              </Button>
              <Button
                variant="textPrimary"
                className="ml-2 text-pinktail-shade"
                onClick={() => {
                  navigateTo(`/me/pages/${page.legacyId}/analytics`);
                  void trackEvent({
                    name: 'mykiss_hamburger_clicked',
                    properties: { action: 'navigate', source: currentPage },
                  });
                }}
              >
                <StatsIcon size={18} />
              </Button>
            </div>
          );
        })}
      </div>
      <Button
        variant="buttonPrimary"
        className={`px-9 text-base ${isMobileDevice ? '' : 'w-full'}`}
        onClick={() => {
          openCreatePostModal();
          void trackEvent({
            name: 'mykiss_hamburger_clicked',
            properties: { action: 'navigate', source: currentPage },
          });
        }}
      >
        Add post
      </Button>
    </Heading>
  ) : (
    <></>
  );
};
