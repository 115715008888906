import { isMobile } from 'react-device-detect';

import { useClientHasRendered } from '../hooks';

/*
  Checks for the actual device that the user is on
  After client has rendered.

  Used where we want to target mobile users specifically.
  e.g. showing app store download buttons 
*/

export const useIsMobileDevice = (): boolean => {
  const clientHasRendered = useClientHasRendered();
  return clientHasRendered && isMobile;
};
