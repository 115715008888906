export const variants = {
  '2xl': 'heading-text-xxl',
  xl: 'heading-text-xl',
  lg: 'heading-text-lg',
  md: 'heading-text-md',
  sm: 'heading-text-sm',
  xs: 'heading-text-xs',
  '2xs': 'heading-text-xxs',
  '3xs': 'heading-text-xxxs',
  none: '',
} as const;
