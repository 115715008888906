import { relayStylePagination } from '@apollo/client/utilities';

export const cacheTypePolicies = {
  typePolicies: {
    Image: {
      keyFields: ['url'],
    },
    StrikethroughPrices: {
      keyFields: ['advertId'],
    },
    MapArea: {
      fields: {
        catches: relayStylePagination(['first']),
      },
    },
    Query: {
      fields: {
        mapArea: {
          keyArgs: ['boundingBox'],
        },
      },
    },
  },
};
