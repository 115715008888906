import { useState, useEffect } from 'react';

import { useSession } from '../contexts/SessionContext';

import { IValidator, ErrorMessage, validateForm } from './forms';

export const useClientHasRendered = (): boolean => useSession().initialClientRenderComplete;

interface UseFormStateResponse<State> {
  state: State;
  errors: ErrorMessage[];
  setState: (newState: State) => void;
  formTouched: boolean;
  setFormTouched: (touched: boolean) => void;
}
export function useFormState<State>(
  data: State,
  validators: IValidator<State>[],
  startAsTouched = false,
): UseFormStateResponse<State> {
  const [formTouched, setFormTouched] = useState(startAsTouched);
  const [errors, setErrors] = useState<ErrorMessage[]>([]);
  const [state, setState] = useState(data);

  const setStateAndFormTouched = (newState: State): void => {
    if (!formTouched) {
      setFormTouched(true);
    }
    setState(newState);
  };

  useEffect(() => {
    setErrors(formTouched ? validateForm(validators, state) : []);
  }, [state, validators, formTouched]);

  return { state, errors, setState: setStateAndFormTouched, formTouched, setFormTouched };
}

interface ISize {
  width?: number;
  height?: number;
}
const getSize = (isClient: boolean): ISize => {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
};
export const useWindowSize = (): ISize => {
  const isClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState<ISize>(getSize(isClient));

  useEffect((): any => {
    const handleResize = (): void => {
      setWindowSize(getSize(isClient));
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isClient]);

  return windowSize;
};
