import { usePathname } from 'next/navigation';

export const useCMSSlug = (): string | undefined => {
  const pathname = usePathname();
  const splitSlug = pathname?.split('/').filter(Boolean);

  if (splitSlug && splitSlug[0] === 'pro-page') {
    /* removes 'pro-page' and any coupon at the end */
    return splitSlug[1];
  }
  if (splitSlug && splitSlug[0] === 'blog') {
    /* removes 'blog' and the blog category */
    return splitSlug[2];
  }
  return undefined;
};
