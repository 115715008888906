type ComplianceRegion =
  | 'EU'
  | 'CA' // California
  | 'EEA' // European economic area
  | 'NONE' // User it outside a compliance region
  | 'UNKNOWN'; // i.e. we have no knowledge of where the user is;

const CALIFORNIA_ISO_CODE = 'CA';
const US_ISO_CODE = 'US';

// Note taht while UK is not in EU anymore, they have retained GDPR laws
// Sourced from https://gist.github.com/henrik/1688572
const EU_ISO_CODES = [
  // -----[ EU 28 ]-----
  'AT', // Austria
  'BE', // Belgium
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IE', // Ireland, Republic of (EIRE)
  'IT', // Italy
  'LV', // Latvia
  'LT', // Lithuania
  'LU', // Luxembourg
  'MT', // Malta
  'NL', // Netherlands
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'GB', // United Kingdom (Great Britain)
  // -----[ Outermost Regions (OMR) ]------
  'GF', // French Guiana
  'GP', // Guadeloupe
  'MQ', // Martinique
  'ME', // Montenegro
  'YT', // Mayotte
  'RE', // Réunion
  'MF', // Saint Martin
  // -----[ Special Cases: Part of EU ]-----
  'GI', // Gibraltar
  'AX', // Åland Islands
  // -----[ Overseas Countries and Territories (OCT) ]-----
  'PM', // Saint Pierre and Miquelon
  'GL', // Greenland
  'BL', // Saint Bartelemey
  'SX', // Sint Maarten
  'AW', // Aruba
  'CW', // Curacao
  'WF', // Wallis and Futuna
  'PF', // French Polynesia
  'NC', // New Caledonia
  'TF', // French Southern Territories
  'AI', // Anguilla
  'BM', // Bermuda
  'IO', // British Indian Ocean Territory
  'VG', // Virgin Islands, British
  'KY', // Cayman Islands
  'FK', // Falkland Islands (Malvinas)
  'MS', // Montserrat
  'PN', // Pitcairn
  'SH', // Saint Helena
  'GS', // South Georgia and the South Sandwich Islands
  'TC', // Turks and Caicos Islands
  // -----[ Microstates ]-----
  'AD', // Andorra
  'LI', // Liechtenstein
  'MC', // Monaco
  'SM', // San Marino
  'VA', // Vatican City
  // -----[ Other ]-----
  'JE', // Jersey
  'GG', // Guernsey
];
const EEA_ISO_CODES = ['CH', 'IS', 'NO']; // Techincally Switzerland is not EEA, but included here for safety.

export function getUserComplianceRegion(
  regionCode?: string,
  countryCode?: string,
): ComplianceRegion {
  if (!countryCode) {
    return 'UNKNOWN';
  }
  if (regionCode === CALIFORNIA_ISO_CODE && countryCode === US_ISO_CODE) {
    return 'CA';
  }
  if (EU_ISO_CODES.includes(countryCode)) {
    return 'EU';
  }
  if (EEA_ISO_CODES.includes(countryCode)) {
    return 'EEA';
  }

  return 'NONE';
}
