import React from 'react';

import { useAnalytics } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../helpers/analytics/trackEvent';
import { useGetOsName } from '../../../helpers/hooks/useGetOsName';
import {
  AppDownloadUrlSourceType,
  getAppDownloadUrl,
} from '../../../helpers/url/getAppDownloadUrl';
import { CloseButton } from '../../Clickables/Controls/CloseButton/CloseButton';
import { ExternalLink } from '../../Clickables/ExternalLink/ExternalLink';
import { Heading } from '../../Heading/Heading';
import { Image } from '../../Image/Image';
import { Text } from '../../Text/Text';

import logo from './logo.png';
import { useIsMobileDevice } from '../../../helpers/hooks/useIsMobileDevice';

interface IProps {
  className?: string;
  description?: string;
  buttonText?: string;
  customAppDownloadUrl?: string;
  showOnDesktop?: boolean;
}

export const StickyBannerGetApp = ({
  className = '',
  description = 'Open in the Fishbrain App',
  buttonText = 'Get app',
  customAppDownloadUrl,
  showOnDesktop = false,
}: IProps) => {
  const [isAppBanner, setIsAppBanner] = React.useState(true);
  const { currentPage } = useAnalytics();
  const isMobileDevice = useIsMobileDevice();
  const osName = useGetOsName();

  const appDownloadUrl =
    customAppDownloadUrl ??
    getAppDownloadUrl({
      appDownloadSourceType: AppDownloadUrlSourceType.StickyBottomBanner,
      currentPageId: currentPage,
      osName,
    });

  if (!showOnDesktop && !isMobileDevice) {
    return <></>;
  }

  if (!isAppBanner) {
    return <></>;
  }

  return (
    <div
      className={`${className} fixed bottom-0 z-high w-full border-t border-twilight bg-white py-3 pr-4 sm:pr-6`}
      data-testid="StickyBannerGetApp"
    >
      <div className="mx-auto flex flex-row items-center sm:max-w-screen-md">
        <CloseButton
          className="text-abyss"
          variant="controlLakeFogBg"
          onClick={() => {
            setIsAppBanner(false);
          }}
          data-testid="closeAppStickyButton"
          id="close-app-sticky-button"
          width={16}
          height={16}
        />
        <Image alt="Fishbrain" src={logo.src} className="max-h-12" />
        <div className="ml-2.5 flex w-1/2 flex-col gap-0.5 pr-2 sm:w-3/5">
          <Heading variant="xs">Fishbrain</Heading>
          <Text variant="sm" className="mb-0 truncate">
            {description}
          </Text>
        </div>
        <ExternalLink
          href={appDownloadUrl}
          variant="buttonPrimary"
          className="mx-auto w-1/4 whitespace-nowrap sm:ml-auto sm:mr-4 sm:w-1/5"
          onClick={() => {
            void trackEvent({
              name: 'mykiss_cta_button_clicked',
              properties: {
                type: 'download',
                source: AppDownloadUrlSourceType.StickyBottomBanner,
                page: currentPage,
              },
            });
          }}
        >
          {buttonText}
        </ExternalLink>
      </div>
    </div>
  );
};
