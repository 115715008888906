import type { AllCompaniesFlows, AllCompaniesNames } from '../../types/general';
import { allCompaniesFlows } from '../general/allCompaniesFlows';

/* Please refrain from using the following constant within any of the Onboarding views to avoid the dependency cycle */
export const flowsURLParameters = Object.entries(allCompaniesFlows).reduce(
  (companies, [companyName, companyFlows]) => ({
    ...companies,
    [companyName]: Object.keys(companyFlows).reduce(
      (flows, flowName) => ({ ...flows, [flowName]: flowName }),
      {},
    ),
  }),
  {},
) as {
  [K in AllCompaniesNames]: {
    [_ in keyof AllCompaniesFlows[K]]: string;
  };
};
