import gql from 'graphql-tag';

import { BrandFragments } from './BrandType';
import { ImageFragments } from './ImageType';

export const ProductFragments = {
  id: gql`
    fragment ProductId on Product {
      _id: externalId
      legacyId: id
    }
  `,
  brandName: gql`
    fragment ProductBrandName on Product {
      brand {
        ...BrandId
        name
      }
    }
    ${BrandFragments.id}
  `,
  // The fields needed to create the data for a ProductListItem
  productListItem: gql`
    fragment ProductListItem on Product {
      _id: externalId
      displayName
      image(height: 100, width: 100, croppingStrategy: NO_CROP) {
        ...ImageFields
      }
      brand {
        ...BrandId
        name
      }
      reviews {
        averageRating: totalAverage(attribute: RATING)
        totalCount
      }
    }
    ${BrandFragments.id}
    ${ImageFragments.imageFields}
  `,
};
