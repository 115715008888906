import dynamic from 'next/dynamic';

const DynamicBecomePro = dynamic(async () =>
  import('./becomePro/BecomePro').then(mod => mod.BecomePro),
);

export const Flows = {
  fishbrain: {
    becomePro: DynamicBecomePro,
  },
};
