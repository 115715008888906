export const getReferringDomain = (referrer: string): string => {
  try {
    const url = new URL(referrer);
    const rootReferrer = url.hostname.replace('www.', '');
    return rootReferrer;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (_error) {
    // This is mainly required for testing environments.
    return '';
  }
};
