import { ITokenResponse, TOKEN_REQUEST_HEADERS } from '../../constants/cognito';

import { getTokenUrl } from './getTokenUrl';
import { returnCognitoResponse } from './shared';

export const refreshTokens = async (refreshToken: string): Promise<ITokenResponse> => {
  const response = await fetch(
    `${getTokenUrl('refresh_token')}&refresh_token=${refreshToken}`,
    TOKEN_REQUEST_HEADERS,
  );
  return returnCognitoResponse(response);
};
