import invariant from 'tiny-invariant';

import { UNIVERSAL_ENV_VARS } from '../../constants/environmentVariables';

const VALID_ENVS = ['staging', 'production'] as const;
type DATABASE_ENV = (typeof VALID_ENVS)[number];

export function getDatabaseEnv(): DATABASE_ENV {
  const env = UNIVERSAL_ENV_VARS.DATABASE_ENV;
  invariant(env, 'No DATABASE_ENV defined');

  if (!VALID_ENVS.find(e => e === env)) {
    throw new Error(`Invalid DATABASE_ENV: ${env}`);
  }

  return env as DATABASE_ENV;
}
