import { IEvent } from '../../interfaces/events';

type TypesWithPropertiesKey<T> = T extends Partial<{ properties: any }> ? T : never;
type IEventWithPropertiesKey = TypesWithPropertiesKey<IEvent>;
type EventProperties =
  | Pick<{ [K in keyof IEventWithPropertiesKey]: IEventWithPropertiesKey[K] }, 'properties'>
  | {};

export const getEventProperties = (event: IEvent): EventProperties => {
  if ('properties' in event) {
    return event.properties;
  }
  return {};
};
