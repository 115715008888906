/* eslint-disable @typescript-eslint/promise-function-async */

import { GetAuthToken } from '../../contexts/AuthContext';
import { IGroupedTopSearch } from '../../interfaces/rutilus/groupedTopSearch';

import { returnJsonOrThrow, get } from './shared';

export const searchForSpecies = async (
  searchTerm: string,
  getAuthToken: GetAuthToken,
  signal?: AbortSignal,
): Promise<IGroupedTopSearch> => {
  return get(
    `/search/grouped?s="${searchTerm.trim()}"&groups[]=species&limit=5`,
    getAuthToken,
    signal,
  ).then(response => {
    return returnJsonOrThrow(response);
  });
};
