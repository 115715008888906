import { MutableRefObject, useRef } from 'react';

export const useHandleUnmount = (): MutableRefObject<boolean> => {
  const unmounted = useRef(false);

  // useEffect(() => {
  //   return () => {
  //
  //     unmounted.current = true;
  //   };
  // }, []);
  return unmounted;
};
