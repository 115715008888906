import gql from 'graphql-tag';

import { ImageFragments } from './ImageType';

export const UserFragments = {
  id: gql`
    fragment UserId on User {
      _id: externalId
    }
  `,
  currentUserId: gql`
    fragment CurrentUserId on CurrentUser {
      _id: externalId
    }
  `,
  avatar: gql`
    fragment UserAvatar on User {
      avatar(croppingStrategy: CENTER, height: 128, width: 128) {
        height
        width
        ...ImageFields
      }
    }
    ${ImageFragments.imageFields}
  `,
  currentUserAvatar: gql`
    fragment CurrentUserAvatar on CurrentUser {
      avatar(croppingStrategy: CENTER, height: 128, width: 128) {
        ...ImageFields
      }
    }
    ${ImageFragments.imageFields}
  `,
};
