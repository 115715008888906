import { GetAuthToken } from '../../contexts/AuthContext';
import { IMapSearchResult } from '../../interfaces/geo';

import { returnJsonOrThrow, get } from './shared';

export const searchForLocation = async (
  searchTerm: string,
  getAuthToken: GetAuthToken,
  signal?: AbortSignal,
): Promise<IMapSearchResult[]> => {
  const response = await get(
    `/maps/geocoding_locations/search?q="${searchTerm}"`,
    getAuthToken,
    signal,
  );
  return returnJsonOrThrow(response);
};
