import gql from 'graphql-tag';

export const ImageFragments = {
  imageFields: gql`
    fragment ImageFields on Image {
      width
      height
      url
    }
  `,
};
