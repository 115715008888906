import {
  ABTestLabel,
  AB_TEST_KEY,
  AB_TEST_PREFIX,
  CURRENT_AB_TESTS,
  PREVIOUS_AB_TESTS_KEY,
} from '../../../constants/abTests';
import { getAmplitudeInstance } from '../../../helpers/analytics/getAmplitudeInstance';
import { setUserProperties } from '../../../helpers/analytics/setUserProperties';
import { reportMykissError } from '../../../helpers/errorHandling';
import { localStorageWrapper } from '../../../helpers/localStorage/localStorageWrapper';
import { getRandomInteger } from '../../../helpers/number';

export const registerABTests = (): void => {
  const previousAbTests = localStorageWrapper.getItem(PREVIOUS_AB_TESTS_KEY);

  const isAbTestsUpdated = JSON.stringify(previousAbTests) !== JSON.stringify(CURRENT_AB_TESTS);

  if (!isAbTestsUpdated) {
    return;
  }

  const abTests: Record<string, string> = {};
  Object.keys(CURRENT_AB_TESTS).forEach(label => {
    if (
      Array.isArray(CURRENT_AB_TESTS[label as ABTestLabel]) &&
      CURRENT_AB_TESTS[label as ABTestLabel].length > 0
    ) {
      const abTestVariants = CURRENT_AB_TESTS[label as ABTestLabel];
      const selectedVariant = abTestVariants[getRandomInteger(0, abTestVariants.length - 1)];

      if (getAmplitudeInstance() !== null) {
        setUserProperties({ [`${AB_TEST_PREFIX}_${label}`]: selectedVariant });
      } else {
        reportMykissError(
          new Error('Could not register user for A/B test as Amplitude has not loaded.'),
          undefined,
          'info',
        );
      }

      abTests[label as ABTestLabel] = selectedVariant;
    }
  });

  if (Object.keys(abTests).length === 0) {
    return;
  }

  try {
    localStorageWrapper.setItem(AB_TEST_KEY, abTests);
    localStorageWrapper.setItem(PREVIOUS_AB_TESTS_KEY, CURRENT_AB_TESTS);
  } catch (error) {
    reportMykissError(error);
  }
};
