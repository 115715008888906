import { useEffect } from 'react';

import { setUserProperties } from '../../../helpers/analytics/setUserProperties';
import { getUserComplianceRegion } from '../../../helpers/location/getUserComplianceRegion';
import {
  OSANO_COOKIE_ACCEPTED,
  useCookiePermissions,
} from '../../CookiePermissionsContext/CookiePermissionsContext';
import { useGeolocation } from '../../GeolocationContext/GeolocationContext';

export function useSetUserProperties(): void {
  const { countryCode, regionCode } = useGeolocation();
  const {
    permissions: { ANALYTICS, ESSENTIAL, MARKETING, PERSONALIZATION },
  } = useCookiePermissions();

  useEffect(() => {
    setUserProperties({
      mykiss_compliance_region: getUserComplianceRegion(regionCode, countryCode),
    });
  }, [countryCode, regionCode]);

  useEffect(() => {
    setUserProperties({
      mykiss_analytics_cookies_accepted: ANALYTICS === OSANO_COOKIE_ACCEPTED,
      mykiss_functional_cookies_accepted: ESSENTIAL === OSANO_COOKIE_ACCEPTED,
      mykiss_personalization_cookies_accepted: PERSONALIZATION === OSANO_COOKIE_ACCEPTED,
      mykiss_marketing_cookies_accepted: MARKETING === OSANO_COOKIE_ACCEPTED,
    });
  }, [ANALYTICS, ESSENTIAL, MARKETING, PERSONALIZATION]);
}
