import gql from 'graphql-tag';

export const upsertConsent = gql`
  mutation UpsertConsent($input: UpsertConsentMutationInput!) {
    upsertConsent(input: $input) {
      userErrors {
        path
        message
      }
    }
  }
`;
