import { GetAuthToken } from '../../contexts/AuthContext';
import { ICoordinates } from '../../interfaces/geo';
import { IFishingWater } from '../../interfaces/rutilus/fishingWaters';

import { returnJsonOrThrow, DEFAULT_PAGE_SIZE, get } from './shared';

export const searchForFishingWater = async (
  getAuthToken: GetAuthToken,
  searchTerm: string,
  userPosition?: ICoordinates,
  signal?: AbortSignal,
  perPage: number = DEFAULT_PAGE_SIZE,
): Promise<IFishingWater[]> => {
  const positionParams = userPosition
    ? `&user_position[lat]=${userPosition.latitude}&user_position[lng]=${userPosition.longitude}&user_position[distance]=100km`
    : '';
  // pre-trim to remove console errors
  const trimmedSearchTerm = searchTerm.trim();
  return get(
    `/fishing_waters/search?s="${trimmedSearchTerm}"&per_page=${perPage}${positionParams}`,
    getAuthToken,
    signal,
  ).then(async response => {
    return returnJsonOrThrow(response);
  });
};
