import { EventMykissNavigatedBetweenWaters } from '../../interfaces/events';

import { trackEvent } from './trackEvent';

export function trackNavigationBetweenWaters(
  method: EventMykissNavigatedBetweenWaters['properties']['method'],
): void {
  if (window.location.pathname.startsWith('/fishing-waters/')) {
    void trackEvent({ name: 'mykiss_navigated_between_waters', properties: { method } });
  }
}
