/**
 * To register a new ABTest, add a new record to the following object. Example:
 * {
 *   mykiss_abtest_paywall: ['withOldPaywall', 'withNewPaywall', 'withoutPaywall',]
 * }
 */
export const CURRENT_AB_TESTS = {
  estimatedShippingTime: ['visible', 'hidden'],
} as const;

export type ABTestLabel = keyof typeof CURRENT_AB_TESTS;
export type ABTestVariant<Label extends ABTestLabel> = (typeof CURRENT_AB_TESTS)[Label][number];

export const AB_TEST_KEY = 'abTests';
export const PREVIOUS_AB_TESTS_KEY = 'previousAbTests';
export const AB_TEST_PREFIX = 'mykiss_abtest';
