import React, { ReactNode, useEffect, useState } from 'react';

import invariant from 'tiny-invariant';

import { IPageMetadata } from '../../interfaces/nextjs';

import { useDatadogRUM } from './hooks/useDatadogRUM';
import { useInitializeAmplitude } from './hooks/useInitializeAmplitude';
import { usePageView } from './hooks/usePageView';
import { useSetUserProperties } from './hooks/useSetUserProperties';

interface IState {
  currentPage: string;
  previousPage: string | null;
}

const AnalyticsContext = React.createContext<IState | null>({
  currentPage: '',
  previousPage: null,
});

type IProps = {
  children: ReactNode;
  blockDatadogRum?: boolean;
} & IPageMetadata;

function AnalyticsProvider({
  _page,
  _pageTitle,
  _pageId,
  _pageSubject,
  blockDatadogRum,
  ...rest
}: IProps) {
  const [currentPage, setCurrentPage] = useState<IPageMetadata>({
    _page,
    _pageTitle,
    _pageId,
    _pageSubject,
  });
  const [previousPage, setPreviousPage] = useState<null | string>(null);

  useDatadogRUM();
  useInitializeAmplitude();
  useSetUserProperties();

  useEffect(() => {
    // Title and subject should not be changing unless ID or page changes.
    if (currentPage._page !== _page || currentPage._pageId !== _pageId) {
      setPreviousPage(currentPage._page);
      setCurrentPage({ _page, _pageTitle, _pageId, _pageSubject });
    }
  }, [currentPage, _page, _pageTitle, _pageId, _pageSubject]);

  usePageView({
    page: currentPage._page,
    pageTitle: currentPage._pageTitle,
    pageId: currentPage._pageId,
    pageSubject: currentPage._pageSubject,
    previousPage,
    blockDatadogRum,
  });

  return (
    <AnalyticsContext.Provider {...rest} value={{ currentPage: currentPage._page, previousPage }} />
  );
}

function useAnalytics(): IState {
  const context = React.useContext(AnalyticsContext);
  invariant(context !== null);

  return context;
}

export { AnalyticsProvider, useAnalytics };
