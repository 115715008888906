import gql from 'graphql-tag';

export const ProductUnitFragments = {
  id: gql`
    fragment ProductUnitId on ProductUnit {
      id
    }
  `,
  // While this may seem like a redundant fragment, it's important for enabling cache updates
  // when a user adds gear to their gearbox (see src/components/ProductUnitList/ProductUnitItem.tsx)
  usedByCurrentUser: gql`
    fragment ProductUnitUsedByCurrentUser on ProductUnit {
      usedByCurrentUser
    }
  `,
};
