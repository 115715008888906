import React, { CSSProperties } from 'react';

import Image from 'next/image';

import avatarPlaceholder from '../../avatar-placeholder.png';

import premium from './premium.svg';

// refactored in FIB-37697
export enum AvatarSize {
  BLOG_AUTHOR = '64',
  CATCH_CARD = '28',
  CATCH_SMALL = '43',
  EXTRA_LARGE = '140',
  LARGE = '120',
  SMALL = '48',
  EXTRA_SMALL = '16',
  MEDIUM = '72',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MEDIUM_PROFILE = '120',
  PREVIEW_CARD = '32',
  REVIEW_SMALL = '35',
  DROPDOWN_SMALL = '24',
  REGISTRY_WALL_MEDIUM = '182',
  REGISTRY_WALL_SMALL = '142',
}

export enum ProStarSize {
  DEFAULT = '17',
  EXTRA_LARGE = '40',
}

export interface IAvatarProps {
  id?: string;
  alt: string;
  className?: string;
  proClassName?: string;
  src: string | undefined;
  size: AvatarSize;
  starSize?: ProStarSize;
  style?: CSSProperties;
  isPro?: boolean;
}

export const Avatar = ({
  size,
  className = '',
  proClassName = '',
  src,
  style = {},
  isPro: _isPro,
  starSize,
  ...rest
}: IAvatarProps) => (
  <div className="relative grid shrink-0">
    <Image
      className={`animate-twilight-bg-pulse col-start-1 row-start-1 rounded-full bg-twilight ${className}`}
      width={size}
      height={size}
      style={style}
      src={src || avatarPlaceholder.src}
      {...rest}
    />
    {_isPro && (
      <Image
        alt="Premium user"
        width={starSize || ProStarSize.DEFAULT}
        height={starSize || ProStarSize.DEFAULT}
        className={`${proClassName} col-start-1 row-start-1 self-auto justify-self-end`}
        src={premium}
      />
    )}
  </div>
);
