import invariant from 'tiny-invariant';

import { UNIVERSAL_ENV_VARS } from '../../constants/environmentVariables';

const VALID_ENVS = ['development', 'testing', 'staging', 'production'] as const;
type MYKISS_ENV = (typeof VALID_ENVS)[number];

export function getMykissEnv(): MYKISS_ENV {
  const env = UNIVERSAL_ENV_VARS.MYKISS_ENV;
  invariant(env, 'No MYKISS_ENV defined');

  if (!VALID_ENVS.find(e => e === env)) {
    throw new Error(`Invalid MYKISS_ENV: ${env}`);
  }

  return env as MYKISS_ENV;
}
