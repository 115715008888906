import React, { useState, useRef, useCallback, useEffect, KeyboardEvent, ChangeEvent } from 'react';

import useOnClickOutside from 'use-onclickoutside';

import { searchForFishingWater } from '../../api/rest/searchForFishingWater';
import { searchForSpecies } from '../../api/rest/searchForSpecies';
import { searchForUser } from '../../api/rest/searchForUser';
import { useRutilusRestQuery } from '../../api/rest/useRutilusRestQuery';
import { useAnalytics } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { useGeolocation } from '../../contexts/GeolocationContext/GeolocationContext';
import { trackEvent } from '../../helpers/analytics/trackEvent';
import { useUserInfo } from '../../helpers/hooks/useUserInfo';
import {
  fishingWaterToSearchOptions,
  groupSearchResults,
  speciesToSearchOptions,
  userToSearchOptions,
} from '../../helpers/search';
import { EventMykissSearchFocused } from '../../interfaces/events';
import { ISearchOption } from '../../interfaces/search';
import { CloseIcon } from '../Icons/CloseIcon';
import { SearchIcon } from '../Icons/SearchIcon';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import {
  DESKTOP_MENU_ICON_HEIGHT,
  DESKTOP_MENU_ICON_WIDTH,
  MOBILE_MENU_ICON_HEIGHT,
  MOBILE_MENU_ICON_WIDTH,
  MOBILE_MENU_ICON_WRAPPER_SIZE,
} from '../Navigation/MainNavigation/constants/constants';

import { SearchOptions } from './components/SearchOptions';

interface IProps {
  placeholder: string;
  width: string | number;
  widthMenu?: string | number;
  parent: EventMykissSearchFocused['properties']['source'];
  className?: string;
  showCloseBtn?: boolean;
  onCloseSearchBox: () => void;
}

export const DEBOUNCE_TIME_IN_MS = 300;

export const SearchBox = ({
  placeholder,
  width,
  widthMenu,
  parent,
  className = '',
  showCloseBtn = false,
  onCloseSearchBox,
}: IProps) => {
  const { coordinates: userPosition } = useGeolocation();
  const containerRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [showResults, setShowResults] = useState(false);
  const [value, setValue] = useState('');
  const [isSearchConfirmed, setIsSearchConfirmed] = useState(false);
  const { currentPage } = useAnalytics();
  const { isLoggedIn } = useUserInfo();

  const { isLoading: isSpeciesFetching, data: speciesResults } = useRutilusRestQuery(
    async (variables, getToken, signal): Promise<ISearchOption[]> => {
      return searchForSpecies(variables.value, getToken, signal).then(({ species }) => {
        return speciesToSearchOptions(species);
      });
    },
    {
      value,
    },
    {},
  );

  const { isLoading: isUserFetching, data: userResults } = useRutilusRestQuery(
    async (variables, getToken, signal): Promise<ISearchOption[]> => {
      return searchForUser(variables.value, getToken, signal).then(({ users }) => {
        return userToSearchOptions(users);
      });
    },
    {
      value,
    },
    { skip: !isLoggedIn },
  );

  const { isLoading: isWatersFetching, data: watersResults } = useRutilusRestQuery(
    async (variables, getToken, signal): Promise<ISearchOption[]> => {
      return searchForFishingWater(
        getToken,
        variables.value,
        variables.userPosition,
        signal,
        3,
      ).then(waters => {
        return fishingWaterToSearchOptions(waters);
      });
    },
    {
      value,
      userPosition,
    },
    {},
  );

  useEffect(() => {
    if (value) {
      setShowResults(true);
    }
  }, [value]);

  useEffect(() => {
    searchInputRef.current?.focus();
    void trackEvent({
      name: 'mykiss_search_focused',
      properties: {
        source: parent,
        page: currentPage,
      },
    });
  }, [parent, currentPage]);
  const isFetching = isWatersFetching || isSpeciesFetching;
  const results = groupSearchResults(userResults || [], watersResults || [], speciesResults || []);

  const onClick = useCallback(() => {
    void trackEvent({
      name: 'mykiss_search_focused',
      properties: {
        source: parent,
        page: currentPage,
      },
    });
  }, [parent, currentPage]);

  const onKeyEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && !isFetching && value) {
        setIsSearchConfirmed(true);
      }
    },
    [isFetching, value, setIsSearchConfirmed],
  );

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const onFocus = useCallback(() => {
    setShowResults(true);
  }, [setShowResults]);

  const onClose = useCallback(() => {
    onCloseSearchBox();
  }, [onCloseSearchBox]);

  const onClickOutside = useCallback(() => {
    void trackEvent({
      name: 'mykiss_search_blurred',
      properties: {
        page: currentPage,
        search_term: value,
        source: parent,
      },
    });
    if (showResults) {
      setShowResults(false);
    }
    onClose();
  }, [value, parent, showResults, onClose, currentPage]);

  useOnClickOutside(containerRef, onClickOutside);

  return (
    <div ref={containerRef}>
      <div className="relative flex text-midnight focus-within:text-primary">
        <label htmlFor="SearchBox" className="absolute inset-y-0 flex cursor-text items-center">
          {isFetching ? (
            <LoadingSpinner isMini />
          ) : (
            <>
              <div className="hidden md:block">
                <SearchIcon width={DESKTOP_MENU_ICON_WIDTH} height={DESKTOP_MENU_ICON_HEIGHT} />
              </div>
              <div className="md:hidden">
                <SearchIcon width={MOBILE_MENU_ICON_WIDTH} height={MOBILE_MENU_ICON_HEIGHT} />
              </div>
            </>
          )}
        </label>
        <input
          ref={searchInputRef}
          className={`ml-2 h-10 w-full rounded-sm bg-white p-2 pl-5 text-midnight focus:rounded-b-none md:pl-8 ${className}`}
          type="text"
          autoComplete="off"
          id="SearchBox"
          data-testid="SearchBox"
          aria-label="Search"
          placeholder={placeholder}
          value={value}
          onClick={onClick}
          onKeyDown={onKeyEnter}
          onChange={onChange}
          onFocus={onFocus}
        />
        {showCloseBtn && (
          <button type="button" onClick={onClose} className="text-abyss">
            <CloseIcon
              width={MOBILE_MENU_ICON_WRAPPER_SIZE}
              height={MOBILE_MENU_ICON_WRAPPER_SIZE}
              strokeWidth={1.5}
            />
          </button>
        )}
      </div>
      {showResults && (
        <div>
          <div
            id="search-results-card"
            className="absolute right-0 overflow-y-auto bg-white"
            style={{ width: widthMenu || width, maxHeight: '90vh' }}
          >
            <SearchOptions
              searchTerm={value}
              results={results}
              onCloseSearchBox={onClose}
              isSearchConfirmed={isSearchConfirmed}
              isAnglersFetching={isUserFetching}
              isSpeciesFetching={isSpeciesFetching}
              isWatersFetching={isWatersFetching}
              source={parent}
            />
          </div>
        </div>
      )}
    </div>
  );
};
