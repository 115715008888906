import { ITokenResponse, TOKEN_REQUEST_HEADERS } from '../../constants/cognito';
import { getDomain } from '../../helpers/urls';

import { getTokenUrl } from './getTokenUrl';
import { returnCognitoResponse } from './shared';

const CALLBACK = '/api/auth';

export const exchangeCodeForTokens = async (code: string): Promise<ITokenResponse> => {
  const response = await fetch(
    `${getTokenUrl('authorization_code')}&code=${code}&redirect_uri=${getDomain()}${CALLBACK}`,
    TOKEN_REQUEST_HEADERS,
  );
  return returnCognitoResponse(response);
};
