/* eslint-disable no-magic-numbers */
import React from 'react';

import { IIconProps } from './constants';

export const SearchIcon = ({
  size = undefined,
  width = 20,
  height = 20,
  title = '',
  ariaLabel = 'Search icon',
  ariaHidden = false,
  className = '',
  ...props
}: IIconProps) => {
  return (
    <svg
      width={size || width}
      height={size || height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={ariaLabel}
      aria-hidden={ariaHidden}
      className={`block ${className}`}
      data-testid="SearchIcon"
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8856 15.2544 12.5971 14.042 13.8557C14.0075 13.8822 13.9744 13.9112 13.9428 13.9428C13.9112 13.9744 13.8822 14.0075 13.8557 14.042C12.5971 15.2544 10.8856 16 9 16C5.13401 16 2 12.866 2 9ZM14.6177 16.0319C13.078 17.2635 11.125 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.125 17.2635 13.078 16.0319 14.6177L19.707 18.2928C20.0975 18.6833 20.0975 19.3165 19.707 19.707C19.3165 20.0975 18.6833 20.0975 18.2928 19.707L14.6177 16.0319Z"
        fill="currentcolor"
      />
    </svg>
  );
};
