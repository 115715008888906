import { getDomain } from '../../helpers/urls';

const PATH = '/api/_cognito/sign-up';

export async function signupWithEmail(email: string, password: string): Promise<void> {
  return fetch(`${getDomain()}${PATH}`, {
    body: JSON.stringify({ username: email, password }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    method: 'POST',
  }).then(async response => {
    if (!response.ok) {
      throw await response.json();
    }
  });
}
