import { exchangeCodeForTokens } from '../../api/cognito/exchangeCodeForTokens';
import { getTokensWithUsernameAndPassword } from '../../api/cognito/getTokensWithUsernameAndPassword';
import { refreshTokens } from '../../api/cognito/refreshTokens';
import { signupWithEmail } from '../../api/cognito/signupWithEmail';
import { ITokenResponse } from '../../constants/cognito';
import { reportMykissError } from '../errorHandling';
import { getSecondsSinceEpoch } from '../time';

import {
  destroyTokens,
  hasRefreshToken,
  IOptions,
  _getAccessToken,
  _getRefreshToken,
  _hasAccessToken,
  _setAccessToken,
  _setIdToken,
  _setRefreshToken,
} from './shared';

async function _refreshAndSetTokens(
  refreshToken: string,
  options?: IOptions,
): Promise<string | null> {
  try {
    const response = await refreshTokens(refreshToken);
    _setAccessToken(response.access_token, getSecondsSinceEpoch() + response.expires_in, options);
    _setIdToken(response.id_token, options);
    return response.access_token;
  } catch (error) {
    reportMykissError(error);
    destroyTokens(options);
    return null;
  }
}

/**
 * Although this is exported, it is only intended for use with AuthContext and should not be directly
 * used!
 */
export async function _getToken(): Promise<string | null> {
  if (_hasAccessToken()) {
    return _getAccessToken();
  }
  if (hasRefreshToken()) {
    return _refreshAndSetTokens(_getRefreshToken());
  }
  return null;
}

/**
 * Although this is exported, it is only intended for use with AuthContext and should not be directly
 * used!
 */
export async function _isAuthenticated(): Promise<boolean> {
  const token = await _getToken();
  return !!token;
}

export async function authenticateWithCode(code: string, options?: IOptions): Promise<void> {
  const response = await exchangeCodeForTokens(code);
  _setAccessToken(response.access_token, getSecondsSinceEpoch() + response.expires_in, options);
  _setRefreshToken(response.refresh_token, options);
  _setIdToken(response.id_token, options);
}

export async function authenticateWithEmailAndPassword(
  email: string,
  password: string,
): Promise<void> {
  const { accessToken, idToken, refreshToken } = await getTokensWithUsernameAndPassword(
    email,
    password,
  );
  _setAccessToken(accessToken.jwtToken, accessToken.expiration);
  _setRefreshToken(refreshToken);
  _setIdToken(idToken.jwtToken);
}

export async function createUserAndSignIn(email: string, password: string): Promise<void> {
  await signupWithEmail(email, password);
  await authenticateWithEmailAndPassword(email, password);
}

export const _forceTokenRefresh = async (): Promise<ITokenResponse> => {
  const response = await refreshTokens(_getRefreshToken());
  _setAccessToken(response.access_token, getSecondsSinceEpoch() + response.expires_in);
  _setIdToken(response.id_token);
  return response;
};
