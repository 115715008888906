import { ReactNode, useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { useAuth } from '../../contexts/AuthContext';
import { useSession } from '../../contexts/SessionContext';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { reportMykissError } from '../../helpers/errorHandling';
import { ACTIVE_FEATURE_FLAGS, filterFeatureFlags } from '../../helpers/featureFlags';
import { useCurrentURL } from '../../helpers/hooks/useCurrentURL/useCurrentURL';
import { useQueryString } from '../../helpers/hooks/useQueryString';
import { localStorageWrapper } from '../../helpers/localStorage/localStorageWrapper';
import { QueryStringKey } from '../../helpers/url/queryUtils';

interface IProps {
  children?: ReactNode;
}

export const ClientSideInitializer = ({ children }: IProps): any => {
  const { setInitialClientRenderComplete } = useSession();
  const { checkForTokens } = useAuth();
  const flagQuery = useQueryString(QueryStringKey.FeatureFlags);
  const { replace } = useRouter();
  const { removeParameters } = useCurrentURL();

  // This effect is used to read the feature flag query parameter from the URL,
  // store it in browsers local storage, and then remove it from the URL.
  useEffect(() => {
    if (isBrowser() && flagQuery !== undefined) {
      const flagValues = typeof flagQuery === 'string' ? [flagQuery] : flagQuery;
      const filteredFlagValues =
        flagValues && flagValues.length > 0 ? filterFeatureFlags(flagValues as string[]) : [];
      localStorageWrapper.setItem(ACTIVE_FEATURE_FLAGS, filteredFlagValues);
      const clearedURL = removeParameters([QueryStringKey.FeatureFlags]);
      try {
        replace(clearedURL);
      } catch (error) {
        reportMykissError(error);
      }
    }
  }, [flagQuery, removeParameters, replace]);

  useEffect(() => {
    if (isBrowser()) {
      // Check if the user has valid auth tokens.
      checkForTokens().catch(reportMykissError);
    }
  }, [checkForTokens]);

  useEffect(() => {
    if (isBrowser()) {
      setInitialClientRenderComplete(true);
    }
  }, [setInitialClientRenderComplete]);

  return children;
};
