import React from 'react';

import { getActiveFeatureFlags } from '../../helpers/featureFlags';
import { useClientHasRendered } from '../../helpers/hooks';

export const FeatureFlagNotification = () => {
  const clientHasRendered = useClientHasRendered();

  if (!clientHasRendered) {
    return <></>;
  }

  const activeFlags = getActiveFeatureFlags();

  return activeFlags.length > 0 ? (
    <div className="pointer-events-none absolute left-0 right-auto top-0 bg-success p-1 text-center text-sea-foam">
      active featureFlags: {activeFlags.map(flag => ` ${flag}`)}
    </div>
  ) : (
    <></>
  );
};
