import { IEvent } from '../../interfaces/events';
import { isTesting } from '../environment/isTesting';
import { logger } from '../logging';

import { getAmplitudeInstance } from './getAmplitudeInstance';
import { getEventProperties } from './getEventProperties';
import { isDatadogSynthetics } from './isDatadogSynthetics';

export async function trackEvent(event: IEvent): Promise<void> {
  const shouldTrackEvent = !isTesting() && !isDatadogSynthetics();
  const eventProperties = getEventProperties(event);

  logger.debug(eventProperties, `EVENT: ${event.name}`);

  if (navigator.userAgent) {
    if (shouldTrackEvent) {
      getAmplitudeInstance()?.logEvent(event.name, eventProperties);
    }
  }
}
