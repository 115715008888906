import { reportMykissError } from '../errorHandling';

interface ILocalStorage {
  setItem: (key: string, value: any) => void;
  getItem: <T>(key: string, defaultValue?: T) => T;
}

export const localStorageWrapper: ILocalStorage = {
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      reportMykissError(error, 'localStorage:setItem', 'warning');
    }
  },
  getItem: (key, defaultValue) => {
    try {
      const item = localStorage.getItem(key);

      if (!item) {
        return defaultValue;
      }
      return JSON.parse(item);
    } catch (error) {
      reportMykissError(error, 'localStorage:getItem', 'warning');
      return defaultValue;
    }
  },
};
