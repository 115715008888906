import { isProduction } from '../helpers/environment/isProduction';
import { getCognitoDomain, getDomain } from '../helpers/urls';

const STAGING_CLIENT_ID = '1je6406pe6er5fomi39qncbn9';
const PRODUCTION_CLIENT_ID = '6a0ka5u9updjin5so6ot5jblf0';
const STAGING_USER_POOL_ID = 'eu-west-1_9AdkO5S3c';
const PRODUCTION_USER_POOL_ID = 'eu-west-1_TKWveIcYu';

export const COGNITO_CLIENT_ID = isProduction() ? PRODUCTION_CLIENT_ID : STAGING_CLIENT_ID;
export const COGNITO_USER_POOL_ID = isProduction() ? PRODUCTION_USER_POOL_ID : STAGING_USER_POOL_ID;

export const TOKEN_REQUEST_HEADERS = {
  method: 'POST',
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
};

export interface ITokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: 'Bearer';
}

const COGNITO_CALLBACK_LOGOUT_URL = getDomain();
export const COGNITO_LOGOUT_URL = `${getCognitoDomain()}/logout?client_id=${COGNITO_CLIENT_ID}&logout_uri=${COGNITO_CALLBACK_LOGOUT_URL}`;
