import { GetCurrentUser_currentUser } from '../../interfaces/graphql';
import { isNewUser } from '../user/isNewUser';

import { trackEvent } from './trackEvent';

export const trackLoginAndAccountCreation = (
  user: GetCurrentUser_currentUser,
  signupMethod: string | undefined,
): void => {
  if (isNewUser(user.createdAt) && signupMethod) {
    void trackEvent({ name: 'account_created', properties: { method: signupMethod } });
  }
  void trackEvent({ name: 'nob_login_complete', properties: {} });
};
