import { SERVER_REQUEST_HEADERS } from '../../constants/constants';
import { GetAuthToken } from '../../contexts/AuthContext';
import { getDatabaseEnv } from '../../helpers/environment/getDatabaseEnv';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { ApiError } from '../../helpers/errors';
import { getSecondsSinceEpoch } from '../../helpers/time';

const getBaseUrl = (): string => {
  switch (getDatabaseEnv()) {
    case 'production':
      return 'https://rutilus.fishbrain.com';
    default:
      return 'https://rutilus.staging.fishbrain.com';
  }
};

const BASE_URL = getBaseUrl();

export type APIError = Response | Error;
export const GRAPHQL_URI = `${BASE_URL}/graphql`;

export const DEFAULT_HEADERS = {
  'X-FIB-PLATFORM': 'mykiss',
  ...(!isBrowser() && SERVER_REQUEST_HEADERS),
};

const getAuthHeaders = async (
  getAuthToken: GetAuthToken,
): Promise<{ Authorization: string } | {}> => {
  const token = await getAuthToken();
  return token && token !== 'undefined' ? { Authorization: `Bearer ${token}` } : {};
};

const buildRestHeaders = async (getAuthToken: GetAuthToken, headers = {}): Promise<{}> => ({
  ...(await getAuthHeaders(getAuthToken)),
  timestamp: getSecondsSinceEpoch().toString(),
  ...DEFAULT_HEADERS,
  ...headers,
});

export const buildGraphqlHeaders = async (getAuthToken: GetAuthToken): Promise<{}> => ({
  'Accept-Language': 'en-us',
  ...(await getAuthHeaders(getAuthToken)),
  timestamp: getSecondsSinceEpoch().toString(),
  ...DEFAULT_HEADERS,
});

export const DEFAULT_PAGE_SIZE = 10;

export const CORS_OPTIONS = { credentials: 'include' };
const POST_OPTIONS: Partial<RequestInit> = { method: 'POST' };
const PUT_OPTIONS: Partial<RequestInit> = { method: 'PUT' };
const DELETE_OPTIONS: Partial<RequestInit> = { method: 'DELETE' };

export const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const send = async (
  path: string,
  getAuthToken: GetAuthToken,
  rawHeaders: {},
  ...options: {}[]
): Promise<Response> => {
  const headers = await buildRestHeaders(getAuthToken, rawHeaders);
  const flattenedOptions = options.reduce((prev, next) => ({ ...prev, ...next }), { headers });
  return fetch(`${BASE_URL}${path}`, flattenedOptions).then((response: Response) => {
    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw response;
    }
    return response;
  });
};

export const destroy = async (
  path: string,
  getAuthToken: GetAuthToken,
  headers = {},
  options = {},
): Promise<Response> => send(path, getAuthToken, headers, DELETE_OPTIONS, CORS_OPTIONS, options);

export const get = async (
  path: string,
  getAuthToken: GetAuthToken,
  signal?: AbortSignal,
  headers = {},
  options = {},
): Promise<Response> => {
  return send(path, getAuthToken, headers, CORS_OPTIONS, options, { signal });
};

export const post = async (
  path: string,
  getAuthToken: GetAuthToken,
  rawBody?: boolean,
  body = {},
  headers = {},
  options = {},
): Promise<Response> =>
  send(
    path,
    getAuthToken,
    headers,
    { body: rawBody ? body : JSON.stringify(body) },
    POST_OPTIONS,
    CORS_OPTIONS,
    options,
  );

export const put = async (
  path: string,
  getAuthToken: GetAuthToken,
  body = {},
  headers: {} = { 'Content-Type': 'application/json', Accept: 'application/json' },
  options = {},
): Promise<Response> =>
  send(
    path,
    getAuthToken,
    headers,
    { body: JSON.stringify(body) },
    PUT_OPTIONS,
    CORS_OPTIONS,
    options,
  );

export const returnResponseOrThrow = async (response: Response): Promise<Response> => {
  if (!response.ok) {
    const bodyText = await response.text();
    throw new ApiError(response, bodyText);
  }
  return Promise.resolve(response);
};

export async function returnJsonOrThrow<T>(response: Response): Promise<T> {
  return returnResponseOrThrow(response).then(async (successfulResponse: Response) =>
    successfulResponse.json(),
  );
}
