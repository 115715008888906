import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { useAnalytics } from '../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackNavigationBetweenWaters } from '../../../helpers/analytics/trackNavigationBetweenWaters';
import { reportMykissError } from '../../../helpers/errorHandling';
import { EventMykissSearchResultSelected } from '../../../interfaces/events';
import { ISearchGroup } from '../../../interfaces/search';
import { Button } from '../../Clickables/Buttons/Button';
import { InternalLink } from '../../Clickables/InternalLink/InternalLink';
import { NonCrawlableLink } from '../../Clickables/NonCrawlableLink/NonCrawlableLink';
import { ISpeciesInfo } from '../../Search/constants';
import { Text } from '../../Text/Text';
import { trackNearbyWatersClicked } from '../helpers/trackNearbyWatersClicked';
import { trackOptionSelected } from '../helpers/trackOptionSelected';

import { EmptyResults } from './EmptyResults';

interface IGroupedOptionsProps {
  group: ISearchGroup;
  searchTerm: string;
  onCloseSearchBox: () => void;
  isFetching: boolean;
  isSearchConfirmed?: boolean;
  source: EventMykissSearchResultSelected['properties']['source'];
  onOptionSelect?: Dispatch<SetStateAction<ISpeciesInfo | undefined>>;
  hideLabel?: boolean;
}

export const GroupedOptions = ({
  group,
  searchTerm,
  onCloseSearchBox,
  isFetching,
  isSearchConfirmed = false,
  source,
  onOptionSelect,
  hideLabel = false,
}: IGroupedOptionsProps) => {
  const { currentPage } = useAnalytics();
  const { push } = useRouter();

  useEffect(() => {
    if (isSearchConfirmed) {
      // Use first search result
      const option = group.options[0];

      if (option) {
        trackOptionSelected(option, source, currentPage, group, searchTerm);
        onCloseSearchBox();
        try {
          push(option.link);
        } catch (error) {
          reportMykissError(error);
        }
      }
    }
  }, [isSearchConfirmed]);
  return (
    <div className="w-full grow py-4 pl-3">
      {!hideLabel && (
        <div className="mb-2 text-sm font-semibold uppercase tracking-wider text-midnight">
          {group.label}
        </div>
      )}

      {!isFetching && !group.options.length && (
        <Text variant="sm" className="mb-0">
          No results.
        </Text>
      )}
      {isFetching && <div data-testid="search-fetching">{EmptyResults}</div>}
      {!isFetching &&
        group.options.map(option => {
          if (option.id === 'nearby-water') {
            return (
              <NonCrawlableLink
                key={option.id}
                routerPushTo="/explore"
                onClickEvent={() => {
                  trackNearbyWatersClicked(searchTerm);
                }}
                variant="textPrimaryDarkBg"
                ariaLabel={option.title}
              >
                <Text variant="sm" className="mb-0 font-semibold">
                  {option.title}
                </Text>
              </NonCrawlableLink>
            );
          }
          if (onOptionSelect) {
            const speciesObj = { speciesId: option.id, displayName: option.title };
            return (
              <Button
                key={option.id}
                variant="textPrimary"
                className="mb-3 block"
                aria-label={option.title}
                onClick={() => {
                  onOptionSelect(speciesObj);
                  onCloseSearchBox();
                }}
              >
                {option.title}
              </Button>
            );
          }
          return (
            <InternalLink
              key={option.id}
              to={option.link}
              variant="textPrimary"
              className="mb-3 block"
              onClick={() => {
                trackOptionSelected(option, source, currentPage, group, searchTerm);
                if (group.type === 'fishing_water') {
                  trackNavigationBetweenWaters('search');
                }
                onCloseSearchBox();
              }}
            >
              <Text variant="sm" className="font-bold mb-0">
                {option.title}
              </Text>

              {option.subtitle && (
                <Text variant="xs" className="mb-0 text-midnight">
                  {option.subtitle}
                </Text>
              )}
            </InternalLink>
          );
        })}
    </div>
  );
};
