import { ReactNode } from 'react';

import { useAnalytics } from '../../../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../../../helpers/analytics/trackEvent';
import { GetManageablePageStatuses_entry_manageablePages_edges_node } from '../../../../../../interfaces/graphql';
import { Button } from '../../../../../Clickables/Buttons/Button';

export interface IBrandLabelProps {
  navigateTo: (path: string) => void;
  page: GetManageablePageStatuses_entry_manageablePages_edges_node;
  children: ReactNode;
}

export const BrandLabel = ({ page, children, navigateTo }: IBrandLabelProps) => {
  const { currentPage } = useAnalytics();
  if (!page.published) {
    return <div className="flex text-base">{children}</div>;
  }
  return (
    <Button
      type="button"
      variant="textPrimary"
      className="flex items-center"
      onClick={() => {
        navigateTo(`/pages/${page.legacyId}`);
        void trackEvent({
          name: 'mykiss_hamburger_clicked',
          properties: { action: 'navigate', source: currentPage },
        });
      }}
    >
      {children}
    </Button>
  );
};
