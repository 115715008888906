import { Map } from 'mapbox-gl';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { Inter, Poppins } from 'next/font/google';
// todo update with FIB-43778
// import { PagesTopLoader } from 'nextjs-toploader/pages';

import '../src/styles/fishbrain.css';
import '../src/styles/index.css';
import '../src/styles/osano.css';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'globalthis/auto';

import { AppLogic } from '../src/components/AppLogic/AppLogic';
import { GlobalLayout } from '../src/components/Layouts/GlobalLayout';
import { hasCookies, initialiseCookies } from '../src/helpers/cookies';
import { reportMykissError } from '../src/helpers/errorHandling';
import { IEvent } from '../src/interfaces/events';
import { IPageMetadata } from '../src/interfaces/nextjs';
import NextTopLoader from 'nextjs-toploader';

declare global {
  interface Window {
    __APOLLO_STATE__: {};
    __BUILDER_APOLLO_STATE__: {};
    __BUILDER_IO_APOLLO_STATE__: {};
    __HAS_AUTH_TOKEN: boolean;
    __PENDING_EVENTS__: IEvent[];
    Cypress: unknown;
    dataLayer?: {}[];
    exploreMap?: Map;
    fbq?: (...args: any) => void; // Facebook pixel
    gapi: any;
    gtag?: (...args: any) => void; // Google Tag Manager
    Helpshift?: (...args: any) => void; // SupportChat
    Osano?: any; // Cookie consent
  }
}

type NextPageWithLayout = NextPage & {
  getLayout?: (component: any) => any;
};

interface AppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
  pageProps: IPageMetadata;
}

const inter = Inter({ variable: '--font-inter', subsets: ['latin'] });
const poppins = Poppins({
  weight: ['500', '600', '900'],
  variable: '--font-poppins',
  subsets: ['latin'],
});

// Initialise cookies. Not in a useEffect hook as we need it before the first render. Note that for
// server/static renders this will not instantiate with the cookies of the request! If you need to
// utilise cookies on the server (e.g. for auth) see `intialiseNextServerCookies`
if (!hasCookies()) {
  initialiseCookies();
}

function MykissApp({ Component, pageProps, router }: AppPropsWithLayout) {
  if (!pageProps._page || !pageProps._pageTitle) {
    // throw new Error(`${router.asPath} is missing pageId or pageTitle`);
    reportMykissError(new Error(`${router.asPath} is missing pageId or pageTitle`));
  }

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ||
    (page => (
      <AppLogic pageProps={pageProps}>
        <GlobalLayout>{page}</GlobalLayout>
      </AppLogic>
    ));

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>
        {`
          :root {
            --font-inter: ${inter.style.fontFamily};
            --font-poppins: ${poppins.style.fontFamily};
          }
        `}
      </style>
      <NextTopLoader />
      {getLayout(<Component {...(pageProps as any)} />)}
    </>
  );
}

export default MykissApp;
