import { ParsedUrlQuery } from 'querystring';

import {
  DrawerView,
  ExploreQueryParams,
  ExploreQueryParamsTypes,
} from '../../../components/Maps/types/types';
import { shallowRemoveBlankValues } from '../../../helpers/objects/shallowRemoveBlankValues';
import { toNumber } from '../../../helpers/strings/toNumber';
import { QueryStringKey } from '../../../helpers/url/queryUtils';
import { MonthEnum } from '../../../interfaces/graphql';

const URL_EXPLORE_PREFIX = 'fib-ex';

const URL_MAPPING_TABLE: Record<keyof ExploreQueryParams, string> = {
  latitude: `${URL_EXPLORE_PREFIX}-lat`,
  longitude: `${URL_EXPLORE_PREFIX}-lng`,
  zoom: `${URL_EXPLORE_PREFIX}-z`,
  search: `${URL_EXPLORE_PREFIX}-s`,
  drawerView: `${URL_EXPLORE_PREFIX}-dv`,
  drawerViewId: `${URL_EXPLORE_PREFIX}-dv-id`,
  mapStyle: `${URL_EXPLORE_PREFIX}-ms`,
  catchFiltersSpecies: `${URL_EXPLORE_PREFIX}-cf-s`,
  catchFiltersMonths: `${URL_EXPLORE_PREFIX}-cf-m`,
  postViewerOpen: QueryStringKey.PostViewerOpen,
  currentPostViewerId: QueryStringKey.CurrentPostViewerId,
};

const getDrawerViewQuery = (drawerView: DrawerView): string =>
  `${URL_MAPPING_TABLE.drawerView}=${drawerView}&${URL_MAPPING_TABLE.drawerViewId}`;

const REDIRECT_QUERY_PARAM_TABLE = {
  catch_id: getDrawerViewQuery('catch'),
  poi_id: getDrawerViewQuery('poi'),
  waypt_id: getDrawerViewQuery('waypoint'),
  ar_id: getDrawerViewQuery('artificial-reef'),
  rule_species_id: URL_MAPPING_TABLE.drawerViewId,
  lat: URL_MAPPING_TABLE.latitude,
  lng: URL_MAPPING_TABLE.longitude,
  z: URL_MAPPING_TABLE.zoom,
  s: URL_MAPPING_TABLE.search,
};

const getNewDrawerView = ({
  query,
  oldDrawerView,
}: {
  query: string;
  oldDrawerView: string;
}): DrawerView | undefined => {
  if (/rule_species_id/.exec(query)) {
    return 'regulation';
  }

  if (oldDrawerView === 'map_layers') {
    return 'map-layers';
  }

  if (oldDrawerView === 'catches' || oldDrawerView === 'regulations') {
    return oldDrawerView;
  }

  return undefined;
};

export const EXPLORE_QUERY_PARAMS_TYPE: ExploreQueryParamsTypes = {
  longitude: 'number',
  latitude: 'number',
  zoom: 'number',
  search: 'string',
  drawerView: 'DrawerView',
  drawerViewId: 'string',
  mapStyle: 'MapStyle',
  catchFiltersMonths: 'string',
  catchFiltersSpecies: 'string',
  postViewerOpen: 'string',
  currentPostViewerId: 'string',
};

export const getValueByName = <T extends keyof ExploreQueryParams>({
  name,
  query,
}: {
  name: T;
  query: ParsedUrlQuery;
}): ExploreQueryParams[T] => {
  const value = query[URL_MAPPING_TABLE[name]];

  if (Array.isArray(value) || value === undefined) {
    return undefined;
  }

  if (EXPLORE_QUERY_PARAMS_TYPE[name] === 'number') {
    return toNumber(value) as ExploreQueryParams[T];
  }

  return value as ExploreQueryParams[T];
};

const redirectExploreQuery = (query: string): string => {
  // remaps all explore query params except drawer
  const newQuery = Object.entries(REDIRECT_QUERY_PARAM_TABLE).reduce(
    (result, [oldKey, newKey]) =>
      result.replace(new RegExp(`(\\?|&)${oldKey}=`), (_, queryChar) => `${queryChar}${newKey}=`),
    query,
  );

  const oldDrawerView = /[?|&]drawer=(.*?)($|&)/.exec(query);

  // remaps drawer explore query param including its value
  if (
    oldDrawerView &&
    oldDrawerView.length >= 2 &&
    getNewDrawerView({ query, oldDrawerView: oldDrawerView[1] }) !== undefined
  ) {
    const oldDrawerViewName = oldDrawerView[1];
    return newQuery.replace(
      new RegExp(`drawer=${oldDrawerViewName}`),
      `${URL_MAPPING_TABLE.drawerView}=${getNewDrawerView({
        query,
        oldDrawerView: oldDrawerView[1],
      })}`,
    );
  }

  return newQuery;
};

// Converts human readable params to url params
const getQueryFromParams = (newQueryParams: Partial<ExploreQueryParams>): Record<string, string> =>
  Object.entries(newQueryParams).reduce(
    (result, [key, value]) => ({
      ...result,
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      [URL_MAPPING_TABLE[key as keyof ExploreQueryParams]]: value?.toString(),
    }),
    {},
  );

// Converts the url params into human readable params
const getParamsFromQuery = (query: Record<string, string>): ExploreQueryParams =>
  Object.keys(URL_MAPPING_TABLE).reduce((result, key) => {
    const name = key as keyof ExploreQueryParams;
    return {
      ...result,
      [name]: getValueByName({ name, query }),
    };
  }, {}) as ExploreQueryParams;

// Converts explore params into a link that can be used on non explore pages
const getExploreUrl = (exploreQueryParams: Partial<ExploreQueryParams>): string => {
  const queryParams = shallowRemoveBlankValues(getQueryFromParams(exploreQueryParams));
  const queryStringList = Object.entries(queryParams).map(([key, value]) => `${key}=${value}`);
  if (queryStringList.length === 0) {
    return '/explore';
  }
  return `/explore?${queryStringList.join('&')}`;
};

// Converts string to array, checking that months from url match month enum
export const getMonthArrayFromUrl = (monthsAsString: string | undefined): MonthEnum[] => {
  const splitstring = monthsAsString?.length ? monthsAsString.split(',') : [];
  if (splitstring.length === 0) {
    return [];
  }
  return splitstring
    .map(month => MonthEnum[month as keyof typeof MonthEnum])
    .filter(month => month !== undefined);
};

export const getSpeciesIdArrayFromUrl = (speciesIdsAsString: string | undefined): string[] => {
  const splitstring = speciesIdsAsString?.length ? speciesIdsAsString.split(',') : [];

  return splitstring;
};

export const convertMonthArrayForUrl = (monthsAsArray: MonthEnum[]): string => {
  return monthsAsArray.join();
};

export const convertSpeciesIdArrayForUrl = (speciesIdsAsArray: string[]): string => {
  return speciesIdsAsArray.join();
};

export { redirectExploreQuery, getQueryFromParams, getParamsFromQuery, getExploreUrl };
